import React ,{Component} from 'react';
import { Redirect  } from 'react-router-dom';


export default class Logout extends Component {
	
	constructor(props) {
    super(props);	
	 
	 this.state = {redirect:false,pagename:'', };
	 };
	
	componentDidMount() {

		localStorage.removeItem("TNuserid");
		localStorage.removeItem("TNusername");
		localStorage.removeItem("TNpassword");
		localStorage.removeItem("TNrole");
	this.setState({
            redirect: true,
			pagename:''
          })
	}
	
	
render() {
	  if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }

return (
  <React.Fragment>
 
   </React.Fragment>
      );

    }
   }
 
