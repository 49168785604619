import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import ReactExport from "react-export-excel";
import ReactToPrint from 'react-to-print';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const headCells = [


 
  
  { id: 'it_title', numeric: false, label: 'Raw Material' },
  { id: 'pl_title', numeric: false, label: 'Godown' },
  { id: 'in_currentbalance', numeric: false, label: 'Quantity' },
   
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Rawmaterialinventoryreport extends Component {
	
    constructor(props) {
    super(props);
  
	 
   
      this.state = {redirect:false,
      matpurdata:[],itid:'',page: 0 ,prevpage:0,totaldata:0,
	  rowsPerPage:100,rowsPaging:[],order:'desc' ,orderby:'in_currentbalance',
	 productid:'',godown:'',godownarray:[],product:'',producttags:[],
	 searcharray:[],exportdata:[],isdisplaytable:"1",notgodowntable:"0"
	  
	  	
	  
	          
      }
       
	   
	}
	
	
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	
	componentDidMount() {  
	
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Top Note - Raw Material Inventory Report";
this.Godownbind();
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 


Godownbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  godownarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.godownarray.map(godown => {
                            
          return {value: godown.pl_id, display:godown.pl_title}
        });
      
		
        this.setState({
          godownarray: [{value: '', display: 'All Godown'},{value: '-1', display: 'Overall'},{value: '0', display: 'All'}].concat(roleFromApi)
        });
      })
	

	
}


  

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
	 

}

apicall(page,rowsPerPage){
	
	  axios.get(Serverurl+'ajaxreports.php?action=inventoryreport&it_type=1&in_itemid='+this.state.productid+'&in_placeid='+this.state.godown+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  matpurdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({matpurdata:[],totaldata:0});
	 }
 
    });
	this.exportapicall();
}



exportapicall(){
	
	  axios.get(Serverurl+'ajaxreports.php?action=inventoryreport&it_type=1&in_itemid='+this.state.productid+'&in_placeid='+this.state.godown)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({exportdata:[],totaldata:0});
	 }

    });
}


handleSubmit= (event) => {
    
    event.preventDefault();
	
	
	let productid = this.state.productid;
	let godown = this.state.godown;
	
	if(godown == -1)
	 {
		this.setState({isdisplaytable:"0",notgodowntable:"2"}) 
	 }
	else 
	{
	    this.setState({isdisplaytable:"1",notgodowntable:"1"}) 
	}
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 
  
  
  

      
	  
	onloadProductBind=(event)=>{
	
		axios.get(Serverurl+'ajaxitem.php?action=productautoextendertype&it_type=1&it_title='+event.target.value)
  .then(resp => { 
 
  	
	 this.setState({searcharray:resp.data});
	
  });
	}		
  

onTagsChange = (event, values) => {
	if(values !== null){
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      producttags: values,
	   product: values["title"],
	   productid: values["year"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }
} 



      
	 

render() {


	
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }


  return (
  <Page layout="public">
<Section slot="header">Raw Material Inventory Report
</Section>


<Section slot="backbutton"> 

<div className="reportoptions">
<ul>

<li><p>
		   
  <ExcelFile element={<button class="expbtn"> Export</button>}>
 <ExcelSheet data={this.state.exportdata} name="Maintainance Report">
<ExcelColumn label="Raw Material" value="it_title"/>
<ExcelColumn label="Godown" value="pl_title"/>
<ExcelColumn label="Quantity" value="in_currentbalance"/>
<ExcelColumn label="Unit" value="unit"/>
						
                </ExcelSheet>                
            </ExcelFile></p></li>
	
	
&nbsp;&nbsp;	
	
		 <li>
		 
		<ReactToPrint
            trigger={() => <p id="print-barcode" className="printrepbtn"> Print </p>}
            content={() => this.componentRef}
          /> 
		 
		 
		 </li>	

</ul>
</div>
</Section>

 <Section slot="main">
 
  <React.Fragment>
    <div className="serachcont">
  <div className="serachcnt">
  <ul>
  
  	
  <li> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadProductBind}  variant="outlined" id="search" placeholder="Product" />
        )}
      /></li>
  
  
  
  
  <li>
  
  							

		<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.godownlabel, value: this.state.godown }}
          onChange={this.myChangeHandler}
          id="godown"
		   value={this.state.godown}
		  name="godown"
		
		
        >
		
		
		{this.state.godownarray.map(godown => (
              
		  <option  
            
              value={godown.value}
            >
              {godown.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
  </li>

			   
			   
  </ul>
  </div>
  	<div className="submitbtns">
<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  

		</div>
<div className="clear"></div>
  </div>	
	  
		
		
   <div ref={el => (this.componentRef = el)} >	  
<div className="ordtbl">

{this.state.isdisplaytable === "1" && ( <React.Fragment>

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead>

<TableRow>
  <TableCell >Raw Material</TableCell>     
  <TableCell >Godown </TableCell> 
  <TableCell >Quantity</TableCell>  
   
						
     <TableCell> </TableCell >                
	 </TableRow>
 
</TableHead>
					  
					   
			
			
			
<TableBody>
                     {(this.state.matpurdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell><a className="link" href={"#/rawmaterialviewmore?tiitemid="+item.in_itemid+""}>{item.it_title}</a></TableCell>
<TableCell><a className="link" href={"#/rawmaterialviewmore?tiitemid="+item.in_itemid+""}>{item.pl_title}</a></TableCell>
<TableCell><a className="link" href={"#/rawmaterialviewmore?tiitemid="+item.in_itemid+""}>{item.in_currentbalance+" "+item.unit}</a></TableCell>

	
	
	
	
 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="5">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
</React.Fragment> )}
  
  
  
{this.state.notgodowntable === "2" && ( <React.Fragment>

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead>

<TableRow>
  <TableCell >Raw Material</TableCell>     

  <TableCell >Quantity</TableCell>  
   
						
     <TableCell> </TableCell >                
	 </TableRow>
 
</TableHead>
					  
					   
			
			
			
<TableBody>
                     {(this.state.matpurdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.it_title}</TableCell>

<TableCell>{item.in_currentbalance+" "+item.unit}</TableCell>

	
	
	
	
 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="5">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
</React.Fragment> )}
  
  
  
  
  
  
  </div>  
</div>
 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}
