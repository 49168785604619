import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Autocomplete from '@material-ui/lab/Autocomplete';

import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
import addimg from './images/add.png';
import cancelimg from './images/cancel.png';

const strProduct=[];
const strProductdeflt='';

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Addproductionsale extends Component {
	
    constructor(props) {
    super(props);
   var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
      this.state = {redirect:false,unit:'',
     page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	  fdate:Date.now(),fdate1:cudate,errormessage:'',open:false,reference:'',
godown:'',godownarray:[],newpcid:"-1",quantity:'',materialtags:[],produserarray:[],
pvdata:[],editclick:0,	matpureditData:[]  ,productionarray:[],prdn:'',
salesinvoice:'' ,amount:'' ,emp:'',emparray:[],selectedproduct:[], 
 strProduct:[],rawtags:'',
rawmaterial:[] ,invoice:'' ,prdnname:'',empname:'',type:'' ,invbal:''         
      }
        this.updateDonenewParent = this.updateDonenewParent; 
	this.onTagsmaterialChange = this.onTagsmaterialChange.bind(this);   
	}
	
	updateDonenewParent = (text) => {
	
		setTimeout(() =>this.newrawproductbind(localStorage.getItem("newitemid")),500);
		}
	
	
newrawproductbind(prid){
					
	 let godown = this.state.godown;
	 
	 	  if(godown==="")
{
	this.setState({errormessage:"Please Select Godown",open:true,
	rawmaterial:[],
	 rawtags:'',
	 selectedproduct:[],unit:''
	
	
	});
		 window.scrollTo({
          top: 0
      })
		 return false;
}
		
	axios.get(Serverurl+'ajaxitem.php?action=productautoextendertype&in_placeid='+godown+'&it_id='+prid)
  .then(resp => { 
 
  
	 this.setState({strProduct:resp.data,
	 selectedproduct:{"year":resp.data[0].year , "title":resp.data[0].title , "unit":resp.data[0].unit , "invbal":resp.data[0].invbal},
	 //selectedproduct:resp.data,
	 rawmaterial:resp.data,
	 rawtags:resp.data[0].title,
	 selectedprid:resp.data[0].year,
	 unit:resp.data[0].unit,
	 invbal:resp.data[0].invbal});
	
  });
 	
		
	}	
	
	
	
	getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	
	
	
	
	
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  } 

	componentDidMount() {  
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }	
	
	
let search = window.location.href;
let trid = this.getParameterByName('trid',search);
	
	document.title = "Top Note -  Production Sale";
	
	
	if(localStorage.getItem("newitemid") !="")
	{
	localStorage.removeItem("newitemid")
	}
	
if(trid!=="" && trid!==null)
{
	
	 this.Materialeditbyid(trid);
}



		this.Materialgetdata();	
	this.Godownbind();
	
	
	  
  
 
}

Materialeditbyid(trid){
	

axios.get(Serverurl+'ajaxtransaction.php?action=list&tr_id='+trid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
		this.setState({  
			  matpureditData: resp.data,
			  fdate: resp.data[0].editdate,
              reference: resp.data[0].tr_referenceno,
              godown: resp.data[0].tr_godownid,
			  prdn:resp.data[0].tr_productionid,
			  fdate1: resp.data[0].date,
			  salesinvoice:resp.data[0].tr_invoiceno,
			  amount:resp.data[0].tr_amount,
			 // emp:resp.data[0].tr_empid,
			  godownname:resp.data[0].godown,
			  
			  prdnname:resp.data[0].prdn,
			  empname:resp.data[0].em_name,
			   type:resp.data[0].tr_type,
			   emp:resp.data[0].tr_customer,
              
              			  
			   
			});
		
	}
  });	
		
	
}

 


myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}


 
myChangeHandlerPV = (event) => {
	
	const re = /^[0-9\b]+$/;
   let nam = event.target.name;
  let val = event.target.value;

  this.setState({[nam]: val});
   
if(nam==="quantity" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
 else{
  this.setState({[nam]: val});
  }
setTimeout(() => this.qtyvalidation(),500);
}


qtyvalidation(){

if(this.state.quantity !='')
{
	//alert(this.state.quantity+"-"+this.state.invbal)
	if(this.state.quantity > this.state.invbal)	
	{
	this.setState({errormessage:"Please enter quantity less than or equal to current stock",open:true,
	quantity:''
	
	
	});
		 window.scrollTo({
          top: 0
      })
		 return false;
	}	
	
}
}




handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  
 

Godownbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  godownarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.godownarray.map(godown => {
                            
          return {value: godown.pl_id, display:godown.pl_title}
        });
      
		
        this.setState({
          godownarray: [{value: '', display: 'Select Godown'}].concat(roleFromApi)
        });
      })
	
this.Productionbind();
	
}



Productionbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=2")
    
      .then((response) => {    
     
      this.setState({  productionarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.productionarray.map(prdn => {
                            
          return {value: prdn.pl_id, display:prdn.pl_title}
        });
      
		
        this.setState({
          productionarray: [{value: '', display: 'Select Production'}].concat(roleFromApi)
        });
      })
	
this.Employeebind();
	
}
 
	
Employeebind(){
	
	
	 axios.get(Serverurl+"ajaxempolyee.php?action=list")
    
      .then((response) => {    
     
      this.setState({  emparray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.emparray.map(emp => {
                            
          return {value: emp.em_id, display:emp.em_name}
        });
      
		
        this.setState({
          emparray: [{value: '', display: 'Select Employee'}].concat(roleFromApi)
        });
      })
	

	
}
	
	
	
	
 
 Materialgetdata() {
	 
	let search = window.location.href;
let trid = this.getParameterByName('trid',search);	
		
		
		
	  if(trid!=="" && trid!==null)
  {
	  
	   axios.get(Serverurl+'ajaxtransaction.php?action=transactionitemgetbyid&ti_tr_id='+trid)
  .then(resp => {  
		let oldOptionspvdata=[]
    if(resp.data.length>0){
		
		  oldOptionspvdata=resp.data;//this.state.pvdata
   
	}
	
	let newrow={"ti_id":this.state.newpcid,"year":"","title":"","unit":"","invbal":"",
	 "ti_itemid":"","ti_quantity":"","ti_isdeleted":0,"tistatus":"1",
	
	 "oldyear":"","oldtitle":"","oldunit":"","oldinvbal":"","oldti_itemid":"","oldti_quantity":""}
	  oldOptionspvdata.unshift(newrow);
	 
	

	

	 let optionsRep = oldOptionspvdata;
	 
	
 this.setState({ 
	 pvdata: oldOptionspvdata ,
	 showField: this.state.newpcid,	
    });
  
});

	  
  } 	
	
	else{
	
	   this.setState({ 
	 pvdata: [{"ti_id":this.state.newpcid,"year":"","title":"","unit":"","invbal":"",
	 "ti_itemid":"","ti_quantity":"","ti_isdeleted":0,"tistatus":"1",
	
	 "oldyear":"","oldtitle":"","oldunit":"","oldinvbal":"","oldti_itemid":"","oldti_quantity":""}] ,
	  showField: this.state.newpcid   
	}); 
	 


	} 
	 
 }


  
   onTagsmaterialChange = (event, values) => {
	  
	     if(values !== null) {
	    let id = this.state.showField;
		 let uni = values["unit"];
		  let inv = values["invbal"];
		 
	this.setState({ selectedproduct:values,
	rawmaterial: values,
	 unit:uni,
     invbal:inv 	 }, () => {
      
     
    });
		   
	 }      
	  
  }
 
  
 
 onAutomaterialextendercall=(event)=>{
	 
	 let godown = this.state.godown;
	 
	 	  if(godown==="")
{
	this.setState({errormessage:"Please Select Godown",open:true,
	rawmaterial:[],
	 rawtags:'',
	 selectedproduct:[],unit:''
	
	
	});
		 window.scrollTo({
          top: 0
      })
		 return false;
} 
	  
	 
 axios.get(Serverurl+'ajaxitem.php?action=productautoextendertype&in_placeid='+godown+'&it_title='+event.target.value)
  .then(resp => { 
  
  
  
   if(resp.data.length>0)
	{
  this.setState({strProduct:resp.data,unit:resp.data[0].unit, invbal:resp.data[0].invbal});
	}
 else{
	  this.setState({strProduct:[],unit:"",invbal:"",
  errormessage:"Product does not Exist",open:true,});
	 window.scrollTo({
          top: 0
      })
		 return false;
  } 
  
  
  
  });
  
}
  


fieldedit(id){
	
	let optionsRep = this.state.pvdata;
	 
    optionsRep.forEach(eachrep => {
		
	
       if (eachrep.ti_id === id)
	   {
		
		   
		   this.state.rawmaterial={"year":eachrep.year , "title":eachrep.title , unit:eachrep.unit , invbal: eachrep.invbal};
	    this.state.rawtags=eachrep.title;
		this.state.quantity=eachrep.ti_quantity;
		this.state.unit=eachrep.unit;
		this.state.invbal=eachrep.invbal;
		this.state.selectedproduct={"year":eachrep.year , "title":eachrep.title , unit:eachrep.unit , invbal: eachrep.invbal};
		this.setState({showField:id,editclick:1})
		}
	
	})
	
}



fielddelete(id){
	
	
	let r = window.confirm("Are you sure to Delete?");
		 if(r == true){
	 
	 let optionsRep = this.state.pvdata;
    optionsRep.forEach(eachrep => {
		
		if(eachrep.tistatus === "1"){
       if (eachrep.ti_id === id)
	   {
		  
		eachrep.ti_isdeleted = 1;
		
		}
		}
		
else{
	
	 if (eachrep.ti_id === id)
	   {
		eachrep.ti_isdeleted = 1;
		
		
	let tiid = eachrep.ti_id ;
		
		
	
					
							
	   }
}

	 
    });
		
	this.setState({pvdata: optionsRep})	
		 }
}


 
 
fieldsave(id){


 var self=this; 
 
	 let optionsRep = self.state.pvdata;
	  var rowsArray = self.state.pvdata; 
	  
	  
	 let val=this.state.selectedproduct["year"];
	 let value=this.state.selectedproduct["title"];
	 let measure=this.state.selectedproduct["unit"];
	  let invbal = this.state.selectedproduct["invbal"];
	 
	
	 
	  if(this.state.selectedproduct["year"] === undefined || this.state.selectedproduct["year"] === null)
	 {
		 this.setState({errormessage:"Please Select Product",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	  if(this.state.quantity === "")
	 {
		 this.setState({errormessage:"Please Enter Quantity",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	
   	
let strsetarray=0;
	
	 if(this.state.editclick !== 1 ){
	
	
	 var newRow = rowsArray.map((row, i) => {
if(i!==0){  

                   
				 
					
					
					
  if (row.year === val && row.ti_quantity!==0) {
	  if(row.ti_isdeleted===1){
		row.ti_isdeleted=0;
		 row.ti_quantity=this.state.quantity;
		
		
	
	
	}
	else{
	  
 this.setState({errormessage:"You Have Already Selected The Same Raw Material, Please Select The Different Raw Material",open:true});
		 window.scrollTo({
          top: 0
      })
	}
  
	  strsetarray=1;
	
  }}
 
  return row;

  });
	}
  if(strsetarray === 1){
	
	
	  
  this.setState({pvdata:newRow,
  rawmaterial:[],
	 rawtags:'',
	 selectedproduct:[],
	  quantity:'',unit:'',
	   invbal:''
   });
  }

	if(strsetarray === 0){
	
optionsRep.forEach(eachrep => {
		
		 if (eachrep.ti_id === id)
	   {
			 
		 eachrep.year= val;
         eachrep.title = value;	   
		eachrep.unit = measure;
        eachrep.invbal = invbal; 			
		eachrep.ti_quantity = this.state.quantity; 
		
	   }
	   
	   
    })  
	
	let vnewpcid=this.state.newpcid;
	
if(this.state.newpcid<0)
{
		 
	if(this.state.editclick!==1)
	{
	  vnewpcid=this.state.newpcid-1;
	  
	    
	  let newrow={"ti_id":vnewpcid,"year":"","title":"","unit":"","invbal":"",
	   "ti_itemid":"","ti_quantity":"","ti_isdeleted":0,"tistatus":"1",
	  "oldyear":"","oldtitle":"","oldunit":"","oldinvbal":"","oldti_itemid":"","oldti_quantity":""}
	    optionsRep.unshift(newrow);
	   
	}
	
}

 self.setState({
	 pvdata: optionsRep ,
	 showField: vnewpcid,
	  newpcid:vnewpcid,
	editclick:0,
     produserarray:[],
       strProduct:[],
	   selectedproduct:'',quantity:'',
	    rawmaterial:[],
	 rawtags:'',unit:'',invbal:''
    
    });

	}
	
 }
  
  
  
fieldcancel(id){
	let search = window.location.href;
   let trid = this.getParameterByName('trid',search);
 
	 let optionsRep = this.state.pvdata;
	
	
	
	  let val=this.state.selectedproduct["year"];
	 let value=this.state.selectedproduct["title"];
	  let measure=this.state.selectedproduct["unit"];
	   let invbal = this.state.selectedproduct["invbal"];
	 
	 
	
 optionsRep.forEach(eachrep => {
		
		 if (eachrep.ti_id === id)
	   {
			 
		 eachrep.year= val;
         eachrep.title = value;	   
		 eachrep.unit = measure;	
         eachrep.invbal = invbal;		
			
			if(trid === null)
			{
			eachrep.ti_quantity = this.state.quantity; 	
			}
			else{
				eachrep.ti_quantity = eachrep.oldti_quantity;
			}
			
		//eachrep.ti_quantity = this.state.quantity; 
		
	   }
	   
	   
    }) 
	
	
let vnewpcid=this.state.newpcid;	
 this.setState({
	 pvdata: optionsRep ,
	 showField: vnewpcid,
	  newpcid:vnewpcid,
	editclick:0,
     produserarray:[],
	 rawmaterial:[],
	 rawtags:'',
     // selectedproduct:{"year":"" , "title":"" },
	   selectedproduct:[],
	   quantity:'',unit:'',
	       invbal:''
	   
	 
	  
    
    }); 
	 
	 
	
	
}
 
  
  
  
  handleDiscard= (event) => {
	
	window.location.reload();
}
  
  
  
  

handleSave = (e) => {
	
 let search = window.location.href;
 let trid = this.getParameterByName('trid',search);

 let fdate1 = this.state.fdate1;
 let prdn = this.state.prdn;
 let godown = this.state.godown;
 let amount = this.state.amount;
 let salesinvoice = this.state.salesinvoice;
 let customer = this.state.emp;
 
  


   if(prdn === "")
	 {
		
		 this.setState({errormessage:"Please Select Production Unit",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
 
 if(godown==="")
{
	this.setState({errormessage:"Please Select Godown",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}

/*
 if(amount==="")
{
	this.setState({errormessage:"Please Enter Amount",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}*/
 if(customer==="")
{
	this.setState({errormessage:"Please Enter the Customer Name",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}

let sep="";
let details="";

 
      
sep=","; 

  var formData = new FormData();
  
  
   
		  
	   
		
          
	 	 if(trid === "" || trid === null)
	  {
		   formData.append('tr_id',0)
		   localStorage.setItem('printtype',"1")
	  }
	  else{
		  formData.append('tr_id',trid)
	  }
	  
	   
	   if(salesinvoice === "" )
	  {
		   formData.append('tr_invoiceno',0)
	  }
	  else{
		  formData.append('tr_invoiceno',salesinvoice)
	  }
	  
if(amount === "" )
	  {
		   formData.append('tr_amount',0)
	  }
	  else{
		  formData.append('tr_amount',amount)
	  }
  
		formData.append('action','insertorupdate') 
	
		formData.append('tr_date',fdate1)
		formData.append('tr_productionid',prdn)
		formData.append('tr_godownid',godown)
		formData.append('tr_invoiceno',salesinvoice)
		//formData.append('tr_amount',amount)
		formData.append('tr_customer',customer)
		formData.append('tr_type',4)
		
		formData.append('userid',localStorage.getItem("TNuserid"))
		
		
		let optionsVal = this.state.pvdata;
		let sep1="";
		let optionValues="";
		let index=0;
		
		//alert(JSON.stringify(optionsVal))
	var srearray=optionsVal.filter(value => value.ti_isdeleted  < 1)
		
	

if(srearray.length-1 === 0)	
{
	 this.setState({errormessage:"Please Select Product ",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}	
		
		
		
    optionsVal.forEach(eachval => {
     
		   if(index!=0)
		   {
	   if(eachval.ti_id<0 && eachval.ti_isdeleted===1)
	   {
		   
	   }
	   else
	   {
		   
 optionValues=optionValues+sep1+eachval.title+"~"+eachval.year+"~"+eachval.ti_quantity+"~"+eachval.ti_id+"~"+eachval.ti_isdeleted+"~"+eachval.oldti_quantity;
   
sep1="`"; 
	   }
		   
		   }
		   index=1;
		   
	 
    })
  
		
	//alert(optionValues)
		formData.append('transactiondetails',optionValues)
		
		axios.post(Serverurl+"ajaxtransaction.php", formData, {
        }).then(res => {
    
          if(res.data!="0"){
		
		var str=res.data;
		this.setState({redirect:true,pagename:'/Saleproductionviewmore?trid='+str+''})
			 }
		  
        });
  
	
  
  
  
};

  
  
 
  Itemaddbox(val){
        this.setState({ itid: val });  
  
         setTimeout(() => this.refs.AddItembox.handleClickOpen(),500);

      }
	  
	  
onInputChange = (event, newTextValue) => {

if(newTextValue!==" "){


  this.setState({rawtags:newTextValue})
}
}	  
 

render() {
	
let search = window.location.href;
let trid = this.getParameterByName('trid',search);	
	
	
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  return (
  <Page layout="public">
<Section slot="header"> Production Sale
</Section>

<Section slot="backbutton"> <a href='#/Adminproductionsale'>Back</a>
</Section>



 <Section slot="main">
 
  <React.Fragment>
  	
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	
  
  <div className="mdetails">
  
  <ul>
  
  
  
  
  
  
  <li>
   {trid !== null && ( <React.Fragment>
  
  <p> Date: {this.state.fdate}</p>
  
  </React.Fragment>)}
  
  {(trid === "undefined" || trid === null) && ( <React.Fragment>
   <p> Date <span className="validation">*</span>
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate || Date.now()}
       // onChange={this.handfrChange}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
		readOnly
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p>
   </React.Fragment>)}
  </li>
  
 
  
  <li>
  {trid !== null && ( <React.Fragment>
  
  <p> Production : {this.state.prdnname}</p>
  
  </React.Fragment>)}
  
{(trid === "undefined" || trid === null) && ( <React.Fragment>  
  							
<p > Production Unit <span className="validation">*</span></p>
		<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "3"}} 
		  defaultValue={{ label: this.state.prdnlabel, value: this.state.prdn }}
          onChange={this.myChangeHandler}
          id="prdn"
		   value={this.state.prdn}
		  name="prdn"
		
		
        >
		{this.state.productionarray.map(prdn => (
            <option  
            
              value={prdn.value}
            >
              {prdn.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
   </React.Fragment>)}
  </li>
  
  
   <li>
 
	{trid !== null && ( <React.Fragment>
  
  <p> Godown : {this.state.godownname}</p>
  
  </React.Fragment>)}

{(trid === "undefined" || trid === null) && ( <React.Fragment>  
<p > Godown <span className="validation">*</span></p>
		<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "4"}} 
		  defaultValue={{ label: this.state.godownlabel, value: this.state.godown }}
          onChange={this.myChangeHandler}
          id="godown"
		   value={this.state.godown}
		  name="godown"
		
		
        >
		{this.state.godownarray.map(godown => (
            <option  
            
              value={godown.value}
            >
              {godown.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
  </React.Fragment>)}
  
  </li>
  
   <li>
  {trid !== null && ( <React.Fragment>
  
  <p> Amount : {this.state.amount}</p>
  
  </React.Fragment>)}
  
  {(trid === "undefined" || trid === null) && ( <React.Fragment>  
  <span className="txtfld">
         <p >Amount </p>
			<p ><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "5"}} 
            fullWidth
			value={this.state.amount}
            id="amount"
          
            name="amount" onChange={this.myChangeHandler}
            autoComplete="amount"
            
			
          /></p></span>
		   </React.Fragment>)}
  
  </li>
  
   
   <li>
 
	 {trid !== null && ( <React.Fragment>
  
  <p> Customer: {this.state.emp}</p>
  
  </React.Fragment>)}
  
  {(trid === "undefined" || trid === null) && ( <React.Fragment> 							
<span className="txtfld">
         <p >Customer <span className="validation">*</span></p>
			<p ><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "6",maxLength:150 }} 
            fullWidth
			value={this.state.emp}
            id="emp"
          
            name="emp" onChange={this.myChangeHandler}
            autoComplete="emp"
            
			
          /></p></span>
    </React.Fragment>)}
  </li>
  </ul>
  </div>
  
  
 
    

	
	<div className="webtbl">
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 
 
 <TableHead> 
<TableRow>
  <TableCell width='40%'><b>Products</b></TableCell>     
  <TableCell width='15%'><b>Current Stock </b></TableCell> 
  <TableCell width='20%'><b>Quantity</b></TableCell>  
  <TableCell width='5%'> </TableCell> 
  <TableCell width='30%'><SyncAltIcon /> </TableCell> 
						
     <TableCell> </TableCell >                 </TableRow></TableHead>

		   
               
                      <TableBody>
					  
					  
	


            {(this.state.pvdata).map((item,index) => {


				
           return ( <React.Fragment>
		   
	
		   
		   
		   {item.ti_isdeleted!==1 && (	 
 <TableRow key={index} >



<TableCell width='40%'>

{this.state.showField!==item.ti_id && (


<p>{item.title}</p>

)}

 
 
 
 {this.state.showField===item.ti_id && (
 
 <React.Fragment>
 {this.state.editclick === 0 && ( <React.Fragment>
 {trid === null && ( <React.Fragment>
 
 
 
 <AddItembox ref="AddItembox" id={this.state.itid}  updateDonenew={this.updateDonenewParent}/>
<p onClick={()=>{this.Itemaddbox(0)}} className="prdlink" > Add New Product / Raw Material</p>

 <Autocomplete
        
        id="free-solo-demo"
		
       
        defaultValue={this.state.rawmaterial}
		 onInputChange={this.onInputChange}
		 inputValue={this.state.rawtags}
		 
	   options={this.state.strProduct}
	    onChange={this.onTagsmaterialChange}  
	   // value={this.state.selectedproduct}
       
  
	 
        renderInput={params => (
          <TextField {...params}  onChange={this.onAutomaterialextendercall}  variant="outlined"  placeholder="Product" />
        )}
		 getOptionLabel={option => option.title}
      />
	
	  
 </React.Fragment>)}
  </React.Fragment>)}
 
 
 
 {this.state.editclick !== 0 && ( <React.Fragment>
 <AddItembox ref="AddItembox" id={this.state.itid}  updateDonenew={this.updateDonenewParent}/>
<p onClick={()=>{this.Itemaddbox(0)}} className="prdlink" > Add New Product / Raw Material</p>

 <Autocomplete
        
        id="free-solo-demo"
		
       
        defaultValue={this.state.rawmaterial}
		 onInputChange={this.onInputChange}
		 inputValue={this.state.rawtags}
		 
	   options={this.state.strProduct}
	    onChange={this.onTagsmaterialChange}  
	   // value={this.state.selectedproduct}
       disabled={true}
  
	 
        renderInput={params => (
          <TextField {...params}  onChange={this.onAutomaterialextendercall}  variant="outlined"  placeholder="Product" />
        )}
		 getOptionLabel={option => option.title}
      />
	  
	  
 </React.Fragment>)}
 
 
  </React.Fragment>)}
 </TableCell>  
 
 
      
 
 
  <TableCell width='15%'> 
  
 {this.state.showField!==item.ti_id && (


<p>{item.invbal} </p>

)}
  
  {this.state.showField===item.ti_id && (<React.Fragment>
  
  
    {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>
  
<p style={{"margin-top":"35px"}}>{this.state.invbal}</p>

 </React.Fragment> )}
  </React.Fragment> )}
  
  {trid === null && ( <React.Fragment>
{this.state.editclick === 0 && ( <React.Fragment>

  <p style={{"margin-top":"35px"}}>{this.state.invbal}</p>
  
   </React.Fragment> )}
  </React.Fragment> )}
  
  
  
  {trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

  <p style={{"margin-top":"35px"}}>{this.state.invbal}</p>
  
   </React.Fragment> )}
  </React.Fragment> )}
  
  
   </React.Fragment> )}
   
   
  </TableCell> 

 
 
	  
	  
	  
	  
	  
<TableCell width='20%'>




 {this.state.showField!==item.ti_id && (


<p>{item.ti_quantity+" "+item.unit}</p>

)}

 
 
 
 {this.state.showField===item.ti_id && (<React.Fragment>
 
{trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>






 <p style={{"margin-top":"35px"}}>
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 /></p>
 </React.Fragment> )}
  </React.Fragment> )}
  
  
  
  {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

 <p style={{"margin-top":"35px"}}>
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="ti_quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 /></p>
  </React.Fragment> )}
  </React.Fragment> )}
  
  
  
 {trid === null && ( <React.Fragment>
  {this.state.editclick === 0 && ( <React.Fragment>

 <p style={{"margin-top":"35px"}}>
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="ti_quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 /></p>
  </React.Fragment> )}
  </React.Fragment> )}
  
  
  
 
 </React.Fragment>)}
</TableCell> 








<TableCell width='5%' >

 {this.state.showField===item.ti_id && (<React.Fragment>
 

 {trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

<p style={{"margin-top":"35px"}}>{this.state.unit}</p>

</React.Fragment> )}
  </React.Fragment> )}


 {trid === null && ( <React.Fragment>
  {this.state.editclick === 0 && ( <React.Fragment>


<p style={{"margin-top":"35px"}}>{this.state.unit}</p>

</React.Fragment> )}
  </React.Fragment> )}


 {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

<p style={{"margin-top":"35px"}}>{this.state.unit}</p>


</React.Fragment> )}
  </React.Fragment> )}



 </React.Fragment>)}
</TableCell> 







{this.state.showField===item.ti_id && (

<TableCell width='30%'> 


<div className="actionbtns">

<ul>


{trid === null && ( <React.Fragment>
<li>

<div className="savebtn" style={{"margin-top":"35px"}}>
<img src={addimg} onClick={()=>{this.fieldsave(item.ti_id)}} />

</div>
 </li> </React.Fragment>
 )}
 
 
 
 {trid !== null && ( <React.Fragment>
  
   {this.state.editclick === 1 && ( <React.Fragment>
<li>

<div className="savebtn" style={{"margin-top":"35px"}}>
<img src={addimg} onClick={()=>{this.fieldsave(item.ti_id)}} />

</div>
 </li>
  </React.Fragment> )}

  </React.Fragment> )}
 
  &nbsp;&nbsp;
  
  
  
   {this.state.editclick === 1 && ( <React.Fragment>  
  <li>
 <div className="cancelbtn" style={{"margin-top":"35px"}}>
 <img src={cancelimg} onClick={()=>{this.fieldcancel(item.ti_id)}} />

</div>
 </li>
  </React.Fragment> )}
 
 </ul>
 
 
</div>
 
</TableCell> 
)}









{this.state.showField!==item.ti_id && (	
<TableCell >

{this.state.editclick !== 1 && (<React.Fragment>

<div className="actionbtns">

<ul><li>

{item.f !=="-1" &&(
<React.Fragment>



<div className="editbtn">
 <img src={editimg} onClick={this.fieldedit.bind(this, item.ti_id)} />

 </div>
 
 
 
 </React.Fragment>  
  )}
  </li>
  &nbsp;&nbsp;
  <li>

  <div className="delbtn">
 <img src={deleteimg} onClick={()=>{this.fielddelete(item.ti_id)}} />
 
 </div>
 </li>
 </ul>
 
 
</div>

</React.Fragment>)}
 </TableCell>
)}



 <TableCell>

 </TableCell>

 </TableRow>
 
)}


 
 
				</React.Fragment>	
				);
					})}
					
					
					
					
					 
					
	
	
                      </TableBody>



</Table>
  </TableContainer> 
 
  </Paper>
   </div>
   
   
  <br/>
   
   <div className="mobtbl">

<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 
 
 <TableHead> 
<TableRow>
					
     <TableCell colSpan="4"> </TableCell >                 
	 </TableRow></TableHead>		   
               
<TableBody>

 {(this.state.pvdata).map((item,index) => {
			
 return ( <React.Fragment>
   
		   
		  	
<div className="tbldisprow">		  	
 <TableRow key={index} >	
 {item.ti_isdeleted!==1 && ( <React.Fragment>

  <TableCell >



 
 
 
 {this.state.showField===item.ti_id && (<React.Fragment>
 
 {this.state.editclick === 0 && ( <React.Fragment>
 
 {trid === null && ( <React.Fragment>
 <p><b>Product</b></p>
 
 <AddItembox ref="AddItembox" id={this.state.itid}  updateDonenew={this.updateDonenewParent}/>
<p onClick={()=>{this.Itemaddbox(0)}} className="prdlink" > Add New Product / Raw Material</p>

  <Autocomplete
        
        id="free-solo-demo"
		
       
        defaultValue={this.state.rawmaterial}
		 onInputChange={this.onInputChange}
		 inputValue={this.state.rawtags}
		 
	   options={this.state.strProduct}
	    onChange={this.onTagsmaterialChange}  
	   // value={this.state.selectedproduct}
       
  
	 
        renderInput={params => (
          <TextField {...params}  onChange={this.onAutomaterialextendercall}  variant="outlined"  placeholder="Product" />
        )}
		 getOptionLabel={option => option.title}
      />
	
	  
 </React.Fragment>)}
 
 
  </React.Fragment>)}
 
 {this.state.editclick !== 0 && ( <React.Fragment>

  <p><b>Product</b></p>
 
 <AddItembox ref="AddItembox" id={this.state.itid}  updateDonenew={this.updateDonenewParent}/>
<p onClick={()=>{this.Itemaddbox(0)}} className="prdlink" > Add New Product / Raw Material</p>

<Autocomplete
        
        id="free-solo-demo"
		
       
        defaultValue={this.state.rawmaterial}
		 onInputChange={this.onInputChange}
		 inputValue={this.state.rawtags}
		 
	   options={this.state.strProduct}
	    onChange={this.onTagsmaterialChange}  
	   // value={this.state.selectedproduct}
       disabled={true}
  
	 
        renderInput={params => (
          <TextField {...params}  onChange={this.onAutomaterialextendercall}  variant="outlined"  placeholder="Product" />
        )}
		 getOptionLabel={option => option.title}
      />
	  
 
 </React.Fragment>)}
 
 </React.Fragment>
 )}


 </TableCell>

</React.Fragment>)}

 
 

   {item.ti_isdeleted!==1 && ( <React.Fragment>

  <TableCell >  
  
   

  
  {this.state.showField===item.ti_id && (<React.Fragment>
 
  
 {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>
  
<p style={{"margin-top":"35px"}}><b>Current Stock : </b> {this.state.invbal}</p>

 </React.Fragment> )}
  </React.Fragment> )}
  
  {trid === null && ( <React.Fragment>
{this.state.editclick === 0 && ( <React.Fragment>

  <p style={{"margin-top":"35px"}}><b>Current Stock : </b> {this.state.invbal}</p>
  
   </React.Fragment> )}
  </React.Fragment> )}
  
  
  
  {trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

  <p style={{"margin-top":"35px"}}><b>Current Stock : </b> {this.state.invbal}</p>
  
   </React.Fragment> )}
  </React.Fragment> )}
  
  
   </React.Fragment> )}
  
  
   </TableCell>
  
 </React.Fragment> )}




 

   {item.ti_isdeleted!==1 && ( <React.Fragment>

   <TableCell  > 
 
  

 
 
 
 {this.state.showField===item.ti_id && (<React.Fragment>

 {trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

 <p style={{"margin-top":"35px"}}>  <b>Quantity</b> 
 < TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="ti_quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
            InputProps={{  maxlength: 5,  }}
			
		 /></p>
		  
		 
  </React.Fragment> )}
  </React.Fragment> )}
 
 
 
 
  {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

 <p style={{"margin-top":"35px"}}> <b>Quantity</b> 
 < TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="ti_quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
           InputProps={{  maxlength: 5,  }}
			
		 /> 
		
		 </p> 
  </React.Fragment> )}
 
  
  
  

  {this.state.editclick === 0 && ( <React.Fragment>

 <p style={{"margin-top":"35px"}}>  <b>Quantity</b> 
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.quantity}
            id="ti_quantity"
           
			type="number"
            name="quantity"
			onChange={this.myChangeHandlerPV}
           InputProps={{  maxlength: 5,  }}
			
		 />
		 
		 </p>
  </React.Fragment> )}
  </React.Fragment> )}

 </React.Fragment>)}
  
  
   </TableCell>
   
 <TableCell>

 
 {this.state.showField===item.ti_id && (<React.Fragment>
 

 {trid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

<p style={{"margin-top":"35px"}}><b> Unit: </b> {this.state.unit}</p>

</React.Fragment> )}
  </React.Fragment> )}


 {trid === null && ( <React.Fragment>
  {this.state.editclick === 0 && ( <React.Fragment>


<p style={{"margin-top":"35px"}}><b> Unit: </b> {this.state.unit}</p>

</React.Fragment> )}
  </React.Fragment> )}


 {trid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>

<p style={{"margin-top":"35px"}}><b> Unit: </b> {this.state.unit}</p>


</React.Fragment> )}
  </React.Fragment> )}



 </React.Fragment>)}

</TableCell> 
   
  
 </React.Fragment> )}

 </TableRow> 
 </div>
 
 
  
   {item.ti_isdeleted!==1 && ( <React.Fragment>
 

{this.state.showField===item.ti_id && (
<TableRow key={index}>
<TableCell colSpan="4"> 


<div className="actionbtns">

<ul>

 {trid === null && ( <React.Fragment>
<li>

<div className="savebtn" style={{"margin-top":"35px"}}>
<img src={addimg} onClick={()=>{this.fieldsave(item.ti_id)}} />

</div>
 </li>
  </React.Fragment>
 )}
 
  {trid !== null && ( <React.Fragment>
  
   {this.state.editclick === 1 && ( <React.Fragment>
<li>

<div className="savebtn" style={{"margin-top":"35px"}}>
<img src={addimg} onClick={()=>{this.fieldsave(item.ti_id)}} />

</div>
 </li>
  </React.Fragment> )}

  </React.Fragment> )}

 
  &nbsp;&nbsp;
 
 
 {this.state.editclick === 1 && ( <React.Fragment> 
  <li>
 <div className="cancelbtn" style={{"margin-top":"35px"}}>
 <img src={cancelimg} onClick={()=>{this.fieldcancel(item.ti_id)}} / >

</div>
 </li>
  </React.Fragment> )}
 

 </ul>
 
 
</div>
</TableCell> 
</TableRow>
)}










 </React.Fragment> )}

	   
		  	
		
				
 		
 {item.ti_isdeleted!==1 && ( <React.Fragment>
 <TableRow key={index} >	
  <TableCell>


{this.state.showField!==item.ti_id && ( <React.Fragment>





<p>{item.title}</p>

</React.Fragment> )}
</TableCell>	

  <TableCell>  


   
 {this.state.showField!==item.ti_id && ( <React.Fragment>

 

  
  
<p>{item.invbal} </p>

</React.Fragment> )}
  </TableCell>
 
 
 
  <TableCell> 	
 
 {this.state.showField!==item.ti_id && (<React.Fragment>
 

 



 
<p>{item.ti_quantity+" "+item.unit} </p>

</React.Fragment>)}
  </TableCell> 
  
  
 
 
 {this.state.showField!==item.ti_id && (	
<TableCell >

{this.state.editclick !== 1 && (<React.Fragment>

<div className="actionbtns">

<ul><li>

{item.f !=="-1" &&(
<React.Fragment>



<div className="editbtn">
 <img src={editimg} onClick={this.fieldedit.bind(this, item.ti_id)} />

 </div>
 
 
 
 </React.Fragment>  
  )}
  </li>
  &nbsp;&nbsp;
  <li>

  <div className="delbtn">
 <img src={deleteimg} onClick={()=>{this.fielddelete(item.ti_id)}} />
 
 </div>
 </li>
 </ul>
 
 
</div>

</React.Fragment>)}


 </TableCell>
)}
</TableRow>
 </React.Fragment>)}


	
		</React.Fragment>	
				);
					})}					
                      </TableBody>



</Table>
  </TableContainer> 
 
  </Paper>
 
  
  
   </div>  
   
   
   
   
   
   
   
   
  
 
  <div className="buttons">

<p className="btnleft">
  
   <Button onClick={this.handleDiscard}  
   type="submit"
            fullWidth
            variant="contained" 
			color="primary">
          Discard
        </Button>
		</p>
		
		
		
   <p className="btnright">
   <Button 
   type="submit"
            fullWidth
            variant="contained" 
			onClick={this.handleSave} 
			color="primary">
          Submit
        </Button></p>
 
<div className="clear"></div>
</div>
 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}



export  class  AddItembox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,title:'',type:'',unit:'',it_id:0,errormessage:'',
		   erropen:false,
   
      
	  }
      
  }
  
  Itemgetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxitem.php?action=list&it_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      it_id:resp.data[0].it_id,
	  title: resp.data[0].it_title,	  
      type: resp.data[0].it_type,
      unit: resp.data[0].it_unit, 	  
	  
    
    });
	//alert(this.state.prevca_icon);
//alert(this.state.)
  }
  
});
	  
  }else{
	 this.setState({  
	 title:'',
	 
	type:'',
	  unit:'',
	  
	 it_id:0
	  }); 
 }
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open, title:'',type:'',unit:'',
  errormessage:'',erropen:false,
   
   
	  });
		this.Itemgetdata();


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
  	if(nam==="phone" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }

}



handleClose = (e) => {

 
 let title=this.state.title;
 let type=this.state.type;
 let unit=this.state.unit;
 
 
 if(title==="")
{
	
	this.setState({errormessage:"Please Enter Title",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

 
if(type==="")
{
	this.setState({errormessage:"Please Enter Type",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}



if(unit==="")
{
	
	this.setState({errormessage:"Please Enter Unit",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

   var formData = new FormData();
  
  
   if(this.state.it_id===0)
	  {
		  formData.append('it_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('it_id',this.state.it_id)
		
          }
	  
	  
	 
        formData.append('action','insertorupdate') 
		
		
		
		formData.append('it_title',title)
		formData.append('it_type',type)
		formData.append('it_unit',unit)
		formData.append('userid',localStorage.getItem("TNuserid"))
		  
		axios.post(Serverurl+"ajaxitem.php", formData, {
        }).then(res => {
			
    if(res.data === -1)
	{
		this.setState({errormessage:"Item with the same name already exist",erropen:true,
		open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
		
	}
	else{
	
	localStorage.setItem("newitemid",res.data);
	
	this.props.updateDonenew();
		
		
 }
          
    
        });
  
	this.setState({ open:false });
};



	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 
render (){

return(
<React.Fragment>

    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Item</DialogTitle>
      <DialogContent>
	  
<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	  	
  	<p>Title <span className="validationtxt">*</span>
	
	<TextField name="title" 
	variant="outlined"
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.title}
			 InputProps={{  maxlength: 150,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
						  
			
 
	<p>Type <span className="validationtxt">*</span></p>
	<p> <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandler}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select</option>
		  <option value={1}>Raw Material</option>
           <option value={2}>Produce Goods</option>
        
		  
          </Select>
      </FormControl>
		  	   </p>
			   
			   
			   
			   
			   <p>Unit <span className="validationtxt">*</span></p>
	<p> <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.unit}
          onChange={this.myChangeHandler}
        name='unit'
         id='unit'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select</option>
          <option value={1}>Kg</option>
          <option value={2}>Mm</option>
		  <option value={3}>Number</option>
          <option value={4}>Gram</option>
		  
          </Select>
      </FormControl>
		  	   </p>
						  
						  
						  
						  
	
		
 
            
			 
						    </DialogContent>
      <DialogActions>
	   <div className="actionbtns">

<ul><li>
<div className="delbtn">
	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
		</div> </li>
 
 &nbsp;  &nbsp;
<li>
<div className="editbtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div></li>
 </ul>
 </div>
      </DialogActions>
    </Dialog>
</React.Fragment>

) }
 
} 
  