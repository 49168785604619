import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import ReactToPrint from 'react-to-print';


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Materialissueviewmore extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      matpureditData:[],fdate:'',fdate1:'',reference:'',godown:'',
	  godownname:'',invoice:'',invoicedate:'',enteredby:'',pvdata:[]
	          
      }
       
	   
	}
	getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	
	

	
	componentDidMount() {

if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }

		
	let search = window.location.href;
let trid = this.getParameterByName('trid',search);


	document.title = "Top Note - Material Purchase Details";
	
	if(trid!=="" && trid!==null)
{
	
	 this.Materialeditbyid(trid);
}
	  
 
} 


Materialeditbyid(trid){
	

axios.get(Serverurl+'ajaxtransaction.php?action=materialissuegetall&tr_id='+trid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
		this.setState({  
			  matpureditData: resp.data,
			  fdate: resp.data[0].editdate,
              prdn:resp.data[0].tr_productionid,
              godown: resp.data[0].tr_godownid,
			  fdate1: resp.data[0].date,
			  godownname:resp.data[0].godown,
			  invoice:resp.data[0].tr_invoiceno,
			   invoicedate:resp.data[0].tr_date,
			   enteredby:resp.data[0].ln_username,
			   prdnname:resp.data[0].prdn,
              emname:resp.data[0].em_name,
              			  
			   
			});
		
	}
	this.Materialgetdata(trid);
  });	
	 	
	
}


Materialgetdata(trid) {


axios.get(Serverurl+'ajaxtransaction.php?action=transactionitemgetbyid&ti_tr_id='+trid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
		 this.setState({  pvdata:resp.data})
   
	}
  });
   if(localStorage.getItem("printtype")!=null )
  {
    setTimeout(() => this.printcall(),800); 
}
}	
 printcall = (e) => {
	
document.getElementById('print-button').click();	
}
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}


render() {
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  return (
  <Page layout="public">
<Section slot="header"> Material Issue Details
</Section>

<Section slot="backbutton"> <a href='#/Adminmaterialissue'>Back</a>
</Section>

 <Section slot="main">
 
  <React.Fragment>
    <div className="ordtbl">
	<div ref={el => (this.componentRef = el)} >
	
	
  
  <div className="trdetails">
  
   <div className="trleft">
   <p><b>Date : {this.state.invoicedate}</b> </p>
   <p>Godown : {this.state.godownname}</p>
   <p>Entered By : {this.state.enteredby}</p>
    
   </div>
  
   <div className="trright">
   <p><b>Slip No. : {this.state.invoice}</b></p>
   <p>Production : {this.state.prdnname}</p>
   <p>Employee Name : {this.state.emname}</p>
   </div>
   
   <div className="clear"></div>
  </div>
  
  
   <div className="productsdiv">
 



<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  

		<TableHead> 
<TableRow>
                       
<TableCell>Raw Material</TableCell> <TableCell>Quantity</TableCell> 
                       
     </TableRow></TableHead>		
					  
                      <TableBody>	
		
 {this.state.pvdata.map((item,index) => { 
 		
           return ( <React.Fragment>	 
 <TableRow key={index} >
	
	<TableCell  > {item.title}</TableCell> 
	<TableCell > {item.ti_quantity+" "+ item.unit}</TableCell> 	
	
	
	</TableRow >
	
	
	</React.Fragment>
	)
 })}


  </TableBody>
</Table>
  </TableContainer>   
  </Paper>



  
  
  </div>
  </div>
  
 

 <div className="printbtn">
 <ReactToPrint
            trigger={() => <p id="print-button" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />
 
</div>
 
</div>
</React.Fragment> 
 </Section>
</Page>
 
  );
}
}
