import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import ReactToPrint from 'react-to-print';


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Maintainanceviewmore extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      mname:'',phone:'',fdate1:'',fdate:'',madate:'',
	  maintaineditData:[],pvdata:[],invoice:'',enteredby:'',
	  aprstatus:''
	  
      }
       
	   
	}
	getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	
	
componentDidUpdate() {
		
 let search = window.location.href;
let maid = this.getParameterByName('maid',search); 
	
 if(maid!=="" && maid!==null)
{
	
	 this.Maintainanceeditbyid(maid);
}
  }

	
	componentDidMount() {  
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	
	let search = window.location.href;
let maid = this.getParameterByName('maid',search);


	document.title = "Top Note - Maintainance Details";
	
if(maid!=="" && maid!==null)
{
	
	 this.Maintainanceeditbyid(maid);
}
	  
 
} 


Maintainanceeditbyid(maid){
	
	

axios.get(Serverurl+'ajaxmaintainance.php?action=list&ma_id='+maid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
		this.setState({  
			  maintaineditData: resp.data,
			  mname: resp.data[0].ma_name,
              phone: resp.data[0].ma_phoneno,
              fdate1: resp.data[0].ma_date,
			  fdate: resp.data[0].ma_date,
			  madate:resp.data[0].ma_date,
			  enteredby:resp.data[0].ln_username,
			  invoice:resp.data[0].ma_invoice,
			  approvedstatus:resp.data[0].ma_approvedstatus,
			  aprstatus:resp.data[0].aprstatus,
              
              			  
			   
			});
		
	}

	
  });	
		
this.Maintainancegetdata(maid);		
	
	
}


Maintainancegetdata(maid) {


axios.get(Serverurl+'ajaxmaintainance.php?action=maintainanceitemgetbyid&mt_ma_id='+maid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
		 this.setState({  pvdata:resp.data})
   
	}
	 
  });
 
}
	


myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}


Receiveproduct(mtid){
	

let r = window.confirm("Do you really want to make the item as Received ?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','itemeceived')
	 formData.append('mt_id',mtid)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	
	 axios.post(Serverurl+"ajaxmaintainance.php", formData, {
        }).then(res => {
    
             
   alert("Item Received Successfully...!");
 
    
  setTimeout(() =>  this.componentDidUpdate() ,500); 
    
            });
 }	
	
}




render() {
	
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	
	

  return (
  <Page layout="public">
<Section slot="header"> Maintainance Details
</Section>

<Section slot="backbutton"> <a href='#/Adminmaintainance'>Back</a>
</Section>

 <Section slot="main">
 
  <React.Fragment>
    <div className="ordtbl">
	
	
	
  <div ref={el => (this.componentRef = el)} >
  <div className="trdetails">
  
   <div className="trleft">
   <p><b>Date : {this.state.madate}</b> </p>   
    <p>Name : {this.state.mname}</p>
	<p>Entered By : {this.state.enteredby}</p>
   </div>
  
   <div className="trright">
   <p><b>Invoice No. : {this.state.invoice}</b></p>
    <p>Phone : {this.state.phone}</p>
	 <p><b>Status : {this.state.aprstatus} </b></p>
  
   </div>
   
   <div className="clear"></div>
  </div>
  
  
   <div className="productsdiv">
 



<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  

		<TableHead> 
<TableRow>
                       
<TableCell>Item Name</TableCell> <TableCell>Type</TableCell> 
<TableCell>Amount</TableCell> <TableCell>Expected Date</TableCell>
<TableCell>Item Status </TableCell>  
                       
     </TableRow></TableHead>		
					  
                      <TableBody>	
		
 {this.state.pvdata.map((item,index) => { 
 		
           return ( <React.Fragment>	 
 <TableRow key={index} >
	
	<TableCell> {item.mt_name}</TableCell> 
	<TableCell> {item.type}</TableCell> 
    <TableCell> {item.mt_amount}</TableCell> 
	<TableCell> {item.date}</TableCell> 


	
	<TableCell> 
	
	{(item.mt_createduserid == localStorage.getItem("TNuserid")) && ( <React.Fragment> 
{item.mt_type === "1"  &&(	<React.Fragment>
	{item.mt_isreceived === "0" && (	<React.Fragment>
	<div className="recbtn">
 <Button onClick={()=>{this.Receiveproduct(item.mt_id)}}>
  Receive
 </Button>
 </div>
 
 </React.Fragment>)}
 	</React.Fragment>)}
	
	{item.mt_type === "1"  &&(	<React.Fragment>
	{ item.mt_isreceived === "1" && (	<React.Fragment>

<p>Received</p> </React.Fragment>)}
		</React.Fragment>)}
		
	
		</React.Fragment>)}
		
	{(item.mt_createduserid !== localStorage.getItem("TNuserid")) && ( <React.Fragment> 
		{item.mt_type == "1" && (	<React.Fragment>
	{item.mt_isreceived === "0" && (	<React.Fragment>
	<p>Not-Received</p>  </React.Fragment>)}
		
		</React.Fragment>)}
		
		{item.mt_type == "1" && (	<React.Fragment>
		{item.mt_isreceived === "1" && (	<React.Fragment>
	<p>Received</p>	   </React.Fragment>)}
		
		</React.Fragment>)}
	
		</React.Fragment>)}
	</TableCell> 
	
	
	</TableRow>
	
	
	</React.Fragment>
	)
 })}


  </TableBody>
</Table>
  </TableContainer>   
  </Paper>



  
  
  </div>
  </div>
  
 
 {this.state.approvedstatus === "1" && ( <React.Fragment>
  <div className="printbtn">
 <ReactToPrint
            trigger={() => <p id="printmain" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />
		  
		  </div>
  </React.Fragment>)}
 

</div>

</React.Fragment> 
 </Section>
</Page>
 
  );
}
}
