import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Addimg from './images/plus-icon.png';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';

import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
import addimg from './images/add.png';
import cancelimg from './images/cancel.png';




const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Addmaintainance extends Component {
	
    constructor(props) {
    super(props);
    var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
      this.state = { redirect: false,mname:'',phone:'',itemname:'',type:'',
	   fdate:Date.now(),fdate1:cudate,errormessage:'',open:false,
     cdate:Date.now(),cdate1:cudate,pvdata:[],newpcid:"-1",amount:'',
	     maintaineditData:[],invoice:''     
      }
      
	   
	}
	
	
	getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	

	componentDidMount() { 

if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }

	
	let search = window.location.href;
let maid = this.getParameterByName('maid',search);
	
	document.title = "Top Note - Maintainance ";
	
	if(maid!=="" && maid!==null)
{
	
	 this.Maintainanceeditbyid(maid);
}

	
	this.Usergetdata();
 
} 

Maintainanceeditbyid(maid){
	
	

axios.get(Serverurl+'ajaxmaintainance.php?action=list&ma_id='+maid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
		this.setState({  
			  maintaineditData: resp.data,
			  mname: resp.data[0].ma_name,
              phone: resp.data[0].ma_phoneno,
              fdate1: resp.data[0].ma_date,
			  fdate: resp.data[0].ma_date,
			  madate:resp.data[0].ma_date,
			  invoice:resp.data[0].ma_invoice,
              
              			  
			   
			});
		
	}
  });	
		
	
	
	
}
handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  } 


myChangeHandler = (event) => {
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
 if(nam==="phone" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
 
}


handleSubmit= (event) => {
    
	 let search = window.location.href;
 let maid = this.getParameterByName('maid',search);
	
    event.preventDefault();
	
	let mname = this.state.mname;
	let cdate1 = this.state.cdate1;
	let phone = this.state.phone;
	let invoice = this.state.invoice;
	
   if(mname === "")
	 {
		
		 this.setState({errormessage:"Please Enter Name",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
 
 if(phone==="")
{
	this.setState({errormessage:"Please Enter Phone",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}


let sep="";
let details="";

 
      
sep=","; 

  var formData = new FormData();
  
    
	 	 if(maid === "" || maid === null)
	  {
		   formData.append('ma_id',0)
		   
	  }
	  else{
		  formData.append('ma_id',maid)
	  }
	  
	
 if(invoice === "" )
	  {
		   formData.append('ma_invoice',0)
	  }
	  else{
		  formData.append('ma_invoice',invoice)
	  }
  
		formData.append('action','insertorupdate') 
	
		formData.append('ma_date',cdate1)
		formData.append('ma_name',mname)
		formData.append('ma_phoneno',phone)
		
		
		formData.append('userid',localStorage.getItem("TNuserid"))
		
		
		let optionsVal = this.state.pvdata;
		let sep1="";
		let optionValues="";
		let index=0;
		
		//alert(JSON.stringify(optionsVal))
	var srearray=optionsVal.filter(value => value.mt_isdeleted  < 1)
		
	

if(srearray.length-1 === 0)	
{
	 this.setState({errormessage:"Please Enter Item Details ",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}	
		
		
		
    optionsVal.forEach(eachval => {
     
		   if(index!=0)
		   {
	   if(eachval.mt_id<0 && eachval.mt_isdeleted===1)
	   {
		   
	   }
	   else
	   {
		   
 optionValues=optionValues+sep1+eachval.mt_name+"~"+eachval.mt_type+"~"+eachval.mt_expecteddate+"~"+eachval.mt_amount+"~"+eachval.mt_id+"~"+eachval.mt_isdeleted;
   
sep1="`"; 
	   }
		   
		   }
		   index=1;
		   
	 
    })
  
		
	//alert(optionValues)
		formData.append('maintainancedetails',optionValues)
		
		axios.post(Serverurl+"ajaxmaintainance.php", formData, {
        }).then(res => {
    
          if(res.data!="0"){
		
		var str=res.data;
		
		this.setState({redirect:true,pagename:'/Adminmaintainance'})
			 }
		  
        });
  
	
  
	
	
} 

handfrChange = (date) => {
	 var month="",day="";
	 
	  if((date.getDate())<10)
	 {
		day="0"+ (date.getDate())
	 }
	 else{
		 day= date.getDate()
	 }
	 
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +day,
displaydate:day + '-' +month + '-' +date.getFullYear() 


	 })

  }


handcrChange = (date) => {
	 var month="",day="";
	 
	  if((date.getDate())<10)
	 {
		day="0"+ (date.getDate())
	 }
	 else{
		 day= date.getDate()
	 }
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ cdate:date,
cdate1:date.getFullYear() + '-' + month + '-' +day


	 })

  }

  handleDiscard= (event) => {
	
	window.location.reload();
}


	


   Usergetdata() {
	 
 let search = window.location.href;
let maid = this.getParameterByName('maid',search);






if(maid !== "" || maid !== null)
{
	  
	  axios.get(Serverurl+'ajaxmaintainance.php?action=maintainanceitemgetbyid&mt_ma_id='+maid)
  .then(resp => {  
		 
		let oldOptionspvdata=[]
    if(resp.data.length>0){
		
		  oldOptionspvdata=resp.data;
		  
		 
   
	}
	
	 
	  
let newrow={"mt_id":this.state.newpcid,"mt_ma_id":"","mt_name":"","mt_type":"","type":"",
	 "mt_expecteddate":"","mt_amount":"","mt_isdeleted":0,"mtstatus":1,"mt_isreceived":"",
	 "oldmt_name":"","oldmt_type":"","oldtype":"","oldmt_expecteddate":"","oldmt_amount":"",
	 }

	 oldOptionspvdata.unshift(newrow);
	 
	

 this.setState({ 
	 pvdata: oldOptionspvdata ,
	 showField: this.state.newpcid
	  
     
       
    
    });
  
  
});




	  
  } 
  else
  {
	  
	
    
	   this.setState({ 
	 pvdata: [{"mt_id":this.state.newpcid,"mt_ma_id":"","mt_name":"","mt_type":"","type":"",
	 "mt_expecteddate":"","mt_amount":"","mt_isdeleted":0,"mt_isreceived":"","mtstatus":1,
	 "oldmt_name":"","oldmt_type":"","oldtype":"","oldmt_expecteddate":"","oldmt_amount":""}] ,
	  showField: this.state.newpcid   
	}); 
	
	  
  } 
        
	 
	 
 }
  
  
  
  
myChangeHandlerPV = (event) => {
	
	const re = /^[0-9\b]+$/;
   let nam = event.target.name;
  let val = event.target.value;
  
  
  this.setState({[nam]: val});
  

}



  
  fieldedit(id){
	
	
	let optionsRep = this.state.pvdata;
	 
    optionsRep.forEach(eachrep => {
		
	
       if (eachrep.mt_id === id)
	   {
		
		this.state.itemname= eachrep.mt_name;		    
	     this.state.type=eachrep.mt_type;
		this.state.fdate=eachrep.date;	
	     this.state.fdate1=eachrep.mt_expecteddate;		    
	     this.state.amount=eachrep.mt_amount;
		 if(this.state.type == "1")
		 {
			  eachrep.type="Returnable";
		 }
		 else{
			  eachrep.type="Non-Returnable";
		 }
			 
		  
		this.setState({showField:id,editclick:1})
		}
	
	})
}
  
  


fielddelete(id){
	//alert()
	
	
	let r = window.confirm("Are you sure to Delete?");
		 if(r == true){
	 let optionsRep = this.state.pvdata;
    optionsRep.forEach(eachrep => {
		if(eachrep.mtstatus === "1"){ 
       if (eachrep.mt_id === id)
	   {
		  
		eachrep.mt_isdeleted = 1;
		   } 
		   
		} 
	   
	 	
else{
	
	 if (eachrep.mt_id === id)
	   {
		eachrep.mt_isdeleted = 1;
		
		
	let mtid = eachrep.mt_id ;
		
		
 var formData = new FormData(); 
 formData.append('action','maintainanceitemdelete')
					 
 formData.append('mt_id',mtid)
formData.append('userid',localStorage.getItem("TNuserid"))
					
axios.post(Serverurl+"ajaxmaintainance.php", formData, {
}).then(res => {
	
});
							
							
							
	   }
}

	 
    });
	this.setState({pvdata: optionsRep})	
		 }
}

  
fieldsave(id){
//alert(id)
	 let optionsRep = this.state.pvdata;
	 
	 
	if(this.state.itemname === "") 
	{
		this.setState({errormessage:"Please Enter Item Name",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	}
	 
	 if(this.state.type === "") 
	{
		this.setState({errormessage:"Please Select Type",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	}
	
	
/*	if(this.state.amount === "") 
	{
		this.setState({errormessage:"Please Enter Amount",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	}*/
	 
	// alert(JSON.stringify(this.state.pvdata))
	optionsRep.forEach(eachrep => {
		
		 if (eachrep.mt_id === id)
	   {
			 
		 eachrep.mt_name=this.state.itemname;		    
	     eachrep.mt_type=this.state.type;
		
	     eachrep.mt_expecteddate=this.state.fdate1;		    
	     eachrep.mt_amount=this.state.amount;
		
		 if(this.state.type == "1")
		 {
			  eachrep.type="Returnable";
		 }
		 else{
			  eachrep.type="Non-Returnable";
		 }
			 
		
		
	   }
	   
	   
    }) 
	 
	
	
	let vnewpcid=this.state.newpcid;
if(this.state.newpcid<0)
{
		 
	if(this.state.editclick!==1)
	{
	  vnewpcid=this.state.newpcid-1;
	
	
	
	//let newrow={"pv_id":vnewpvid,"pv_value":"","pv_score":"","pv_isdeleted":0,"oldvalue":"","oldscore":""}
	  
let newrow={"mt_id":vnewpcid,"mt_ma_id":"","mt_name":"","mt_type":"","type":"",
	 "mt_expecteddate":"","mt_amount":"","mt_isdeleted":0,"mt_isreceived":"","mtstatus":1,
	 "oldmt_name":"","oldmt_type":"","":"oldtype","oldmt_expecteddate":"","oldmt_amount":""}
	   


	  optionsRep.unshift(newrow);
	} 
	
}
 this.setState({ 
	 pvdata: optionsRep ,
	 showField: vnewpcid,
	  newpcid:vnewpcid,editclick:0,
     itemname:'',
	 type:'',
	 amount:'',
	
     
       
    
    });
 }
  
	
	
 
fieldcancel(id){
	
   let search = window.location.href;
   let maid = this.getParameterByName('maid',search);
   
   
	 let optionsRep = this.state.pvdata;
	
 optionsRep.forEach(eachrep => {
		
		 if (eachrep.mt_id === id)
	   {
			 
		 eachrep.mt_name=this.state.itemname;		    
	     eachrep.mt_type=this.state.type;
		
	     eachrep.mt_expecteddate=this.state.fdate1;		    
	     eachrep.mt_amount=this.state.amount;
		 if(this.state.type == "1")
		 {
			  eachrep.type="Returnable";
		 }
		 else{
			  eachrep.type="Non-Returnable";
		 }
			
			if(maid === null)
			{
			eachrep.ti_quantity = this.state.quantity; 	
			}
			else{
				eachrep.ti_quantity = eachrep.oldti_quantity;
			}
			
		//eachrep.ti_quantity = this.state.quantity; 
		
	   }
	   
	   
    }) 
	
	
let vnewpcid=this.state.newpcid;	
 this.setState({
	 pvdata: optionsRep ,
	 showField: vnewpcid,
	  newpcid:vnewpcid,
	editclick:0,
    itemname:'',
	 type:'',
	 amount:'',
     
	   
	 
	  
    
    }); 
	
} 



render() {
let search = window.location.href;
let maid = this.getParameterByName('maid',search);
/*
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }*/
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	

  return (
  <Page layout="public">
<Section slot="header"> Maintainance
</Section>
<Section slot="backbutton"> <a href='#/Adminmaintainance'>Back</a>
</Section>

 <Section slot="main">
 <React.Fragment> 
 

 
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
 
 <div className="mdetails">
  <ul>
  
   <li>
   
   {maid !== null && ( <React.Fragment>
  
  <p> Name: {this.state.mname}</p>
  
  </React.Fragment>)}
   
   
  {(maid === "undefined" || maid === null) && ( <React.Fragment> 
   
   <span className="txtfld">
    <p>Name <span className="validationtxt">*</span></p>
   <p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.mname}
            id="mname"
          
            name="mname" onChange={this.myChangeHandler}
            autoComplete="mname"
            placeholder="Name"
			
          /></p></span>
		  
		  </React.Fragment>)}
		  
		
		  
   </li>
   
   <li>
   
   {maid !== null && ( <React.Fragment>
  
  <p> Phone: {this.state.phone}</p>
  
  </React.Fragment>)}
   
   {(maid === "undefined" || maid === null) && ( <React.Fragment> 
   
   <span className="txtfld">
    <p>Phone <span className="validationtxt">*</span></p>
   <p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.phone}
            id="phone"
          
            name="phone" onChange={this.myChangeHandler}
            autoComplete="phone"
            placeholder="Phone"
			
          /></p></span>
		  
		  </React.Fragment>)}
   </li>
   <li>
   
   {maid !== null && ( <React.Fragment>
  
  <p> Date: {this.state.madate}</p>
  
  </React.Fragment>)}
   
   
   {(maid === "undefined" || maid === null) && ( <React.Fragment>
   <p>  Date <span className="validation">*</span>
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.cdate || Date.now()}
       // onChange={this.handcrChange}
            format="dd/MM/yyyy"
        autoOk name="cdate"
        ampm={false}
		readOnly
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p>
  </React.Fragment>)}
  </li>
  
  
  
   </ul>
   </div>
   
   <div className="webtbl">
   <Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
   <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                       
   <TableCell>Item Name</TableCell> <TableCell>Type</TableCell> 
   <TableCell>Amount</TableCell><TableCell>Expected Date</TableCell>
    <TableCell ></TableCell> <TableCell ></TableCell>
                       
     </TableRow></TableHead>
					  
				
                      <TableBody>

 {(this.state.pvdata).map((item,index) => {  
     
		  
		   return ( <React.Fragment>
	{item.mt_isdeleted!==1 && (

 <TableRow key={index} >




<TableCell>

{this.state.showField!==item.mt_id && (


<p>{item.mt_name}</p>

)}




{this.state.showField===item.mt_id && ( <React.Fragment>

 
{maid === null && ( <React.Fragment>
{this.state.editclick !== 1 && ( <React.Fragment>	
 <span className="txtfld"><p> <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>

	</React.Fragment>)}	
</React.Fragment>)}
 
  
{maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	


 <span className="txtfld"><p> <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>

	</React.Fragment>)}	
</React.Fragment>)}	


{maid !== null && ( <React.Fragment>
 {this.state.editclick === 1 && ( <React.Fragment>




 <span className="txtfld"><p> <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>

	</React.Fragment>)}	
</React.Fragment>)}	





	
</React.Fragment>)}
</TableCell>

<TableCell>

{this.state.showField!==item.mt_id && (

<p>{item.type}</p>
)}



{this.state.showField===item.mt_id && (<React.Fragment>


 {maid === null && ( <React.Fragment>

{this.state.editclick !== 1 && ( <React.Fragment>	
<span className="txtfld"><p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   
			   </React.Fragment>)}
</React.Fragment>)}


{maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	

<span className="txtfld"><p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   
			   </React.Fragment>)}
 </React.Fragment>)}




{maid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	

<span className="txtfld"><p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   
			   </React.Fragment>)}
 </React.Fragment>)}



 </React.Fragment>)}
</TableCell>


 
<TableCell>

{this.state.showField!==item.mt_id && (

<p>{item.mt_amount}</p>
)}



{this.state.showField===item.mt_id && ( <React.Fragment>



 {maid === null && ( <React.Fragment>
{this.state.editclick !== 1 && ( <React.Fragment>	
<span className="txtfld"><p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
 </React.Fragment>)}
  </React.Fragment>)}
 
 
 
 {maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	
<span className="txtfld"><p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
 </React.Fragment>)}
  </React.Fragment>)}
 
					



 {maid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	
<span className="txtfld"><p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
 </React.Fragment>)}
  </React.Fragment>)}




					

</React.Fragment>)}	
</TableCell>


 
<TableCell>

{this.state.showField!==item.mt_id && (

<p>{item.mt_expecteddate}</p>
)}



{this.state.showField===item.mt_id && ( <React.Fragment>



 {maid === null && ( <React.Fragment>
 {this.state.editclick !== 1 && ( <React.Fragment>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
						  
 </React.Fragment>)}
  </React.Fragment>)}
 
 
 
 {maid === null && ( <React.Fragment>
  {this.state.editclick === 1 && ( <React.Fragment>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	 </React.Fragment>)}					  
 </React.Fragment>)}
 
 
 
 
 
 {maid !== null && ( <React.Fragment>
  {this.state.editclick === 1 && ( <React.Fragment>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	 </React.Fragment>)}					  
 </React.Fragment>)}
 
 
 
 
 
 
</React.Fragment>)}						  
</TableCell>




{this.state.showField===item.mt_id && (

<TableCell > 



<div className="actionbtns">

<ul>

{maid === null && ( <React.Fragment>
<li>

<div className="savebtn" >
<img src={addimg} onClick={()=>{this.fieldsave(item.mt_id)}} />

</div>
 </li>
  </React.Fragment>
 )}
 
 
 
 {maid !== null && ( <React.Fragment>
  
   {this.state.editclick === 1 && ( <React.Fragment>
<li>

<div className="savebtn" >
<img src={addimg} onClick={()=>{this.fieldsave(item.mt_id)}} />

</div>
 </li>
  </React.Fragment> )}

  </React.Fragment> )}
  
 
  &nbsp;&nbsp;
  {this.state.editclick === 1 && ( <React.Fragment>  
  <li>
 <div className="cancelbtn" >
 <img src={cancelimg} onClick={()=>{this.fieldcancel(item.mt_id)}}  />

</div>
 </li>
 </React.Fragment> )}
 
 
 </ul>
 
 
</div>
 

 
 
</TableCell> 
)}






{this.state.showField!==item.mt_id && (	
<TableCell align="left">

{this.state.editclick !== 1 && (<React.Fragment>

<div className="actionbtns">

<ul><li>

{item.f !=="-1" &&(
<React.Fragment>



<div className="editbtn">
 <img src={editimg} onClick={this.fieldedit.bind(this, item.mt_id)} />
 
 </div>
 
 
 
 </React.Fragment>  
  )}
  </li>
  &nbsp;&nbsp;
  <li>

  <div className="delbtn">
 <img src={deleteimg} onClick={()=>{this.fielddelete(item.mt_id)}} />
 
 </div>
 </li>
 </ul>
 
 
</div>

</React.Fragment>)}
 
 </TableCell>
)}





 </TableRow>
 
	)}		

				</React.Fragment>	
				);
					})}
                      </TableBody>


</Table>
  </TableContainer> 
   
  </Paper>
   
   </div>
   
   <br/>
   
   
    <div className="mobtbl">
   <Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
   <Table stickyHeader aria-label="sticky table">  

	 <TableHead> 
<TableRow>
					
     <TableCell colSpan="5"> </TableCell >                 
	 </TableRow></TableHead>				  
				
                      <TableBody>

 {(this.state.pvdata).map((item,index) => {  
     
		  
		   return ( <React.Fragment>
	
<div className="tbldisprow">	
 <TableRow key={index} >
 {item.mt_isdeleted!==1 && (<React.Fragment>
 
  <TableCell colSpan="5" width="100%">
  
 {this.state.showField===item.mt_id && ( <React.Fragment>

 
{maid === null && ( <React.Fragment>
{this.state.editclick !== 1 && ( <React.Fragment>	 
<span className="mobtextbx">
<p><b>Item Name</b></p>

<p > <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>	
						  </React.Fragment>)}	
</React.Fragment>)}





{maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	


<span className="mobtextbx">
<p><b>Item Name</b></p>
<p> <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>

	</React.Fragment>)}	
</React.Fragment>)}	


{maid !== null && ( <React.Fragment>
 {this.state.editclick === 1 && ( <React.Fragment>




<span className="mobtextbx">
<p><b>Item Name</b></p>
<p> <TextField 
 variant="outlined"
            margin="normal"
                           name="itemname" 
						 
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.itemname}
						   
						   
			inputProps={{ tabIndex: "1",maxLength:150 }}    
			 InputLabelProps={{shrink: true,  }}
			  autofocus="on"
                          /></p></span>

	</React.Fragment>)}	
</React.Fragment>)}	

 
 
 
  </React.Fragment>)}
  </TableCell>

 </React.Fragment>)}




  {item.mt_isdeleted!==1 && (<React.Fragment>

  <TableCell colSpan="5">
  
  
  {this.state.showField===item.mt_id && (<React.Fragment>


 {maid === null && ( <React.Fragment>

{this.state.editclick !== 1 && ( <React.Fragment>
  
  <p><b>Type</b></p>
<span className="txtfld">


<p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   </React.Fragment>)}
</React.Fragment>)}
			   
			   
			   

{maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	

  <p><b>Type</b></p>
<span className="txtfld">
<p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   
			   </React.Fragment>)}
 </React.Fragment>)}




{maid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	

  <p><b>Type</b></p>
<span className="txtfld">
<p > <FormControl  className="stadrp">
       
        <Select
		variant="outlined"
        native
         value={this.state.type}
          onChange={this.myChangeHandlerPV}
        name='type'
         id='type'
		 
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>Select Type</option>
          <option value={1}>Returnable</option>
          <option value={2}>Non-Returnable</option>
		 
		  
          </Select>
      </FormControl>
		  	   </p></span>
			   
			   </React.Fragment>)}
 </React.Fragment>)}



 </React.Fragment>)}			   
			   
 
  </TableCell>
  </React.Fragment>)}

 
  
  

   {item.mt_isdeleted!==1 && (<React.Fragment>

   <TableCell colSpan="5">
   
   
{this.state.showField===item.mt_id && ( <React.Fragment>



 {maid === null && ( <React.Fragment>
{this.state.editclick !== 1 && ( <React.Fragment>	
   
   
 <span className="mobtextbx">
 
 <p><b>Amount</b></p>
 <p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
						</React.Fragment>)}
  </React.Fragment>)}
   
						  
						  
	
 {maid === null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	
<span className="mobtextbx">
 
 <p><b>Amount</b></p>
 <p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
 </React.Fragment>)}
  </React.Fragment>)}
 
					



 {maid !== null && ( <React.Fragment>
{this.state.editclick === 1 && ( <React.Fragment>	
<span className="mobtextbx">
 
 <p><b>Amount</b></p>
 <p> <TextField

 name="amount" 
 variant="outlined"
            margin="normal"
                          
						
						   onChange={this.myChangeHandlerPV} 
						   value={this.state.amount}
						    type="number"
						inputProps={{ tabIndex: "4",maxLength:150 }}     
			 InputLabelProps={{  shrink:true,  }}
			  autofocus="on"
                          />
						  
						  
						  
						  </p></span>
 </React.Fragment>)}
  </React.Fragment>)}




					

</React.Fragment>)}						  
 
  </TableCell>
   </React.Fragment>)}

  
  
  
  

   {item.mt_isdeleted!==1 && (<React.Fragment>

<TableCell colSpan="5">





{this.state.showField===item.mt_id && ( <React.Fragment>



 {maid === null && ( <React.Fragment>
 {this.state.editclick !== 1 && ( <React.Fragment>

<p><b>Expected Date</b></p>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	</React.Fragment>)}
  </React.Fragment>)}
  
  
  
  
 {maid === null && ( <React.Fragment>
  {this.state.editclick === 1 && ( <React.Fragment>
  <p><b>Expected Date</b></p>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	 </React.Fragment>)}					  
 </React.Fragment>)}
 
 
 
 
 
 {maid !== null && ( <React.Fragment>
  {this.state.editclick === 1 && ( <React.Fragment>
  <p><b>Expected Date</b></p>
<p className="dropdown">
<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  name="fdate"
	  variant="outlined"
	  inputVariant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate}
        onChange={this.handfrChange}
	   // onChange={this.myChangeHandlerPV}
            format="dd/MM/yyyy"
        autoOk 
        ampm={false}
		
		
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	 </React.Fragment>)}					  
 </React.Fragment>)}
 
 
 
 
 
 
</React.Fragment>)}	
  
 
  </TableCell>
 </React.Fragment>)}
  </TableRow>
  
  </div>
  
  
  
  
  <TableRow> 
   {item.mt_isdeleted!==1 && (<React.Fragment>



{this.state.showField===item.mt_id && (

<TableCell colSpan="6"> 



<div className="actionbtns">

<ul>

{maid === null && ( <React.Fragment>
<li>

<div className="savebtn" >
<img src={addimg} onClick={()=>{this.fieldsave(item.mt_id)}} />

</div>
 </li>
  </React.Fragment>
 )}
 
 
 
 {maid !== null && ( <React.Fragment>
  
   {this.state.editclick === 1 && ( <React.Fragment>
<li>

<div className="savebtn" >
<img src={addimg} onClick={()=>{this.fieldsave(item.mt_id)}} />

</div>
 </li>
  </React.Fragment> )}

  </React.Fragment> )}
  
 
  &nbsp;&nbsp;
  {this.state.editclick === 1 && ( <React.Fragment>  
  <li>
 <div className="cancelbtn" >
 <img src={cancelimg} onClick={()=>{this.fieldcancel(item.mt_id)}}  />

</div>
 </li>
 </React.Fragment> )}
 
 
 </ul>
 
 
</div>
 

 
 
</TableCell> 
)}
 
  

 </React.Fragment>)}
 </TableRow>
 
 
 
{item.ti_isdeleted!==1 && ( <React.Fragment> 
 <TableRow key={index}>	
<TableCell>

{this.state.showField!==item.mt_id && (


<p>{item.mt_name}</p>

)}
</TableCell>



  <TableCell>  
  
  {this.state.showField!==item.mt_id && (

<p>{item.type}</p>
)}
  
  </TableCell>  
  
  
   <TableCell>  
  

{this.state.showField!==item.mt_id && (

<p>{item.mt_amount}</p>
)}
  
  </TableCell>  
  
  <TableCell>  
  

{this.state.showField!==item.mt_id && (

<p>{item.mt_expecteddate}</p>
)}
  
  </TableCell> 
  
  
  

{this.state.showField!==item.mt_id && (	
<TableCell align="left">

{this.state.editclick !== 1 && (<React.Fragment>

<div className="actionbtns">

<ul><li>

{item.f !=="-1" &&(
<React.Fragment>



<div className="editbtn">
 <img src={editimg} onClick={this.fieldedit.bind(this, item.mt_id)} />
 
 </div>
 
 
 
 </React.Fragment>  
  )}
  </li>
  &nbsp;&nbsp;
  <li>

  <div className="delbtn">
 <img src={deleteimg} onClick={()=>{this.fielddelete(item.mt_id)}} />
 
 </div>
 </li>
 </ul>
 
 
</div>

</React.Fragment>)}
 
 </TableCell>
)}
  
   </TableRow>
 </React.Fragment>)}

				</React.Fragment>	
				);
					})}
                      </TableBody>


</Table>
  </TableContainer> 
   
  </Paper>
   
   </div>
   
   
 
 
  <div className="buttons">

<p className="btnleft">
  
   <Button onClick={this.handleDiscard}  
   type="submit"
            fullWidth
            variant="contained" 
			color="primary">
          Discard
        </Button>
		</p>
		
		
		
   <p className="btnright">
   <Button 
   type="submit"
            fullWidth
            variant="contained" 
			onClick={this.handleSubmit} 
			color="primary">
          Submit
        </Button></p>
 
<div className="clear"></div>
</div>
  

 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}

  