import React ,{Component} from 'react';


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import login_pagelogo from './images/login-screen-logo.jpg';


import loginimg from "./images/vector.jpg";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {'© Copyright '}
	   {new Date().getFullYear()}
      <Link color="inherit" >
       {' '} Topnote Aromatics, Mysore. All rights reserved.
      </Link>{' '}<br/>
	  
      
     
    </Typography>
  );
}



export default class Login extends Component {


  constructor(props) {
    super();

    this.state = { us_id: '',username: '', password: '',pagename:'',strreturn:'', redirect: false,checked:true,checkedlogin:false};
   
}
   myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
  
}

handleChckbxChange = (event) => {
   
	this.setState({checkedlogin: !this.state.checkedlogin});

  };
  
  
handleChange = (event) => {
   
	this.setState({checked: !this.state.checked});
  };
componentDidMount() {
	if(localStorage.getItem("TNusername")!=null)
	{
		 this.setState({
            username: localStorage.getItem("username"),
			password:localStorage.getItem("password"),
          })
	}
	
	
	
	
	
	
	if(localStorage.getItem("userid")!=null)
	{
           
		 if(localStorage.getItem("role")!=null)
		  {  
		   
		   if(localStorage.getItem("role")=="1")
		  {
		   
		  
		   
	} 
		  }	
		  
		  
		  
		  
		  
		  
	}
}

 
   handleSubmit= (event) => {
    event.preventDefault();
	
    let username = this.state.username;
    let password = this.state.password;
	let ischeck = this.state.checked;
   var formData = new FormData();
    formData.append('action','logingetdata')
	
	formData.append('ln_username',username)
    formData.append('ln_password',password)
    axios.post(Serverurl+"ajaxlogin.php", formData, {
        }).then(res => {


		let strreturn=res.data 	;	 
 if(res.data[0].ln_id === "0")
 {
	 alert("Username/ Password is not valid");
	 return false;
 }else
 {

           localStorage.setItem("TNuserid",res.data[0].ln_id);
		 localStorage.setItem("TNrole",res.data[0].ln_role);
		   
this.inventoryinsert(res.data[0].invdate);

	 if(ischeck==true)
  {
	   localStorage.setItem("TNusername",this.state.username);
	  localStorage.setItem("TNpassword",this.state.password);
	
  }else{
	 localStorage.removeItem("TNusername");
	  localStorage.removeItem("TNpassword"); 
  }
  
  
  if(localStorage.getItem("TNrole") == "1")
		  {
			  
			  
		  
		   this.setState({
            redirect: true,
			pagename:'Admindashboard'
          })
		  }
		  
		  else if(localStorage.getItem("TNrole") == "2"){
			  
			
		   this.setState({
            redirect: true,
			pagename:'Admindashboard'
          })
		  }  
			  
		 else if(localStorage.getItem("TNrole") == "3"){		  
			
			   this.setState({
            redirect: true,
			pagename:'Managerdashboard'
          })
		  }
		   else if(localStorage.getItem("TNrole") == "5"){		  
			
			   this.setState({
            redirect: true,
			pagename:'Managerdashboard'
          })
		  }
		  
		  else{
			  
			 this.setState({
            redirect: true,
			pagename:'Adminmarkitems'
          })  
			  
		  }
  	 
	//this.setState({redirect:true,	pagename:'Admindashboard'});     

		  
 }
 
		
            });
  
  }
  
  
inventoryinsert(date){  
	  
	  
		var formData = new FormData();
		formData.append('action','dailyinventoryinsert')		
		formData.append('vdate',date)	   
		axios.post(Serverurl+"ajaxlogin.php", formData, {
			}).then(res => {

			})			
	  
}
  
 
  
  
   
  
  render() {
	  
	 if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	  
  
  return (
	 <React.Fragment>  
	 
	 <div className="loginmain">
	 
	 <p><img src={login_pagelogo}  /></p>
	 
	 <div className="lgcntmain">
	 
	 <div className="loginleft">
	 
	 <p><img src={loginimg}  /></p>
	 </div>
	 
	 
	<div className="loginright">
	
	<div className="loginbg">
	<div className="loginspace">

 <form className={useStyles.form}   onSubmit={this.handleSubmit} >




<div className="textinput">
		<h1> LOGIN </h1> 
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
			color="#ffffff"
           autoFocus
            fullWidth
			value={this.state.username}
			inputProps={{ tabIndex: "1",maxLength:150 }} 
            id="usernamelogin"
			placeholder="Username"
			 InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
                       type="username"
            name="username" onChange={this.myChangeHandler}
            
			
          /></p>
		  
		  	  
		 
		  <br/>
		  
		 
		   <p>
		  <TextField
            variant="outlined"
			className="bordercolor"
            margin="normal"
           inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.password}
             type="password"
            id="passwordlogin"
			placeholder="Password"
            name="password" onChange={this.myChangeHandler}
            	 InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="iconcolor" >
              <LockIcon />
            </InputAdornment>
          ),
        }}
			
          /></p>
		  
		 </div> 
		  
          
		  
		<div className="remember"> <Grid container>
            <Grid item xs>
              
            </Grid>
            <FormControlLabel
            control={<Checkbox value="remember" inputProps={{ tabIndex: "3" }}  checked={this.state.checked} color="primary" onChange={this.handleChange} />}
            label="Keep Signed in"
          />
		
          </Grid>  </div>
	
          <p className="submitlogin"><Button
            type="submit"
            fullWidth
            variant="contained" 
           // color="primary"    
		   inputProps={{ tabIndex: "4" }} 
			id="submitlogin" 
            className="submitlogin"
          >
            Login Now 
          </Button></p>
         
      </form></div></div>
	</div>
	
	
	
	
	
	 <div className="clear"></div>
	 
	 </div>
	 
	  <div className="footersection">
  <Copyright />
     
</div>
	 
	 </div> 
	 
	 
	 
	 
	 
	 
	 
		
	</React.Fragment>
	



  );
  }
}



