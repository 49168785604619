import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch,HashRouter ,Redirect } from 'react-router-dom';
import { LayoutProvider } from 'react-page-layout';


import logo from './logo.svg';


import './App.css';

import Adminitem from './components/Adminitem';
import Master from './components/Master';
import Login from './components/Login';
import Adminplace from './components/Adminplace';
import Adminemployee from './components/Adminemployee';
import Loginmaster from './components/Loginmaster';
import Changepassword from './components/Changepassword';
import Addmaterialpurchase from './components/Addmaterialpurchase';
import Adminmaterialpurchase from './components/Adminmaterialpurchase';
import Addmaterialissue from './components/Addmaterialissue';
import Adminmaterialissue from './components/Adminmaterialissue';
import Addproduction from './components/Addproduction';
import Adminproduction from './components/Adminproduction';
import Addproductionsale  from './components/Addproductionsale';
import Adminproductionsale from './components/Adminproductionsale';
import Addmaintainance from './components/Addmaintainance';
import Adminmaintainance from './components/Adminmaintainance';
import Materialpurchaseviewmore from './components/Materialpurchaseviewmore';
import Materialissueviewmore from './components/Materialissueviewmore';
import Productionviewmore from './components/Productionviewmore';
import Saleproductionviewmore from './components/Saleproductionviewmore';
import Maintainanceviewmore from './components/Maintainanceviewmore';
import Materialpurchasereport from './components/Materialpurchasereport';
import Productionreport from './components/Productionreport';
import Materialissuereport  from './components/Materialissuereport';
import Productionsalereport from './components/Productionsalereport';
import Maintainancereport from './components/Maintainancereport';
import Returanableitemsreport from './components/Returanableitemsreport';
import Logout from './components/Logout';
import AppLayout from './components/AppLayout';
import Displaymenu from './components/Displaymenu';
import Rawmaterialinventoryreport from './components/Rawmaterialinventoryreport';
import Productinventoryreport from './components/Productinventoryreport';
import Admindashboard from './components/Admindashboard';
import Adminmarkitems from './components/Adminmarkitems';
import Managerdashboard from './components/Managerdashboard';
import Maintainmasterviewmore from  './components/Maintainmasterviewmore';
import Markedproducts from './components/Markedproducts';
import Dailyinventoryreport from './components/Dailyinventoryreport';
import Productionissuereport from './components/Productionissuereport';
import Rawmaterialviewmore from './components/Rawmaterialviewmore';
import Productsviewmore from './components/Productsviewmore';
const layouts = {
	'public': Master,
};

const aplayout = {
	'public': AppLayout,
};

class App extends Component {
render() {
    return ( 
	
	<HashRouter basename="/"> <Switch>
	
	
	<LayoutProvider layouts={aplayout} path="/Dailyinventoryreport">
	<Dailyinventoryreport />				
    </LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Markedproducts">
	<Markedproducts />				
    </LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Maintainmasterviewmore">
	<Maintainmasterviewmore />				
    </LayoutProvider>
	
		<LayoutProvider layouts={aplayout} path="/Managerdashboard">
	   <Managerdashboard />				
       </LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Adminmarkitems">
	<Adminmarkitems />
				
</LayoutProvider>
	
<LayoutProvider layouts={aplayout} path="/Productinventoryreport" >
	<Productinventoryreport />
				
</LayoutProvider>	
	
	
<LayoutProvider layouts={aplayout} path="/Rawmaterialinventoryreport" >
				<Rawmaterialinventoryreport />
				
			</LayoutProvider>	
	
	
	
	<LayoutProvider layouts={aplayout} path="/Admindashboard" >
				<Admindashboard />
				
			</LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Returanableitemsreport" >
				<Returanableitemsreport />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Maintainancereport" >
				<Maintainancereport />
				
			</LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Productionsalereport" >
				<Productionsalereport />
				
			</LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Materialissuereport" >
				<Materialissuereport />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Productionreport" >
				<Productionreport />
				
			</LayoutProvider>
	
		<LayoutProvider layouts={aplayout} path="/Productionissuereport" >
				<Productionissuereport />
				
			</LayoutProvider>
	
	
	
	<LayoutProvider layouts={aplayout} path="/Materialpurchasereport" >
				<Materialpurchasereport />
				
			</LayoutProvider>
	
	
	
		<LayoutProvider layouts={aplayout} path="/Maintainanceviewmore" >
				<Maintainanceviewmore />
				
			</LayoutProvider>
	
	
	
	
	<LayoutProvider layouts={aplayout} path="/Saleproductionviewmore" >
				<Saleproductionviewmore />
				
			</LayoutProvider>
	
	
	
	<LayoutProvider layouts={aplayout} path="/Productionviewmore" >
				<Productionviewmore />
				
			</LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Materialissueviewmore" >
				<Materialissueviewmore />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Materialpurchaseviewmore" >
				<Materialpurchaseviewmore />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Rawmaterialviewmore" >
				<Rawmaterialviewmore />
				
			</LayoutProvider>
		<LayoutProvider layouts={aplayout} path="/Productsviewmore" >
				<Productsviewmore />
				
			</LayoutProvider>
	<LayoutProvider layouts={aplayout} path="/Adminmaintainance" >
				<Adminmaintainance />
				
			</LayoutProvider>
	
	
	<LayoutProvider layouts={aplayout} path="/Addmaintainance" >
				<Addmaintainance />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Adminproductionsale" >
				<Adminproductionsale />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Addproductionsale" >
				<Addproductionsale />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Adminproduction" >
				<Adminproduction />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Addproduction" >
				<Addproduction />
				
			</LayoutProvider>
	
	<LayoutProvider layouts={aplayout} path="/Adminmaterialissue" >
				<Adminmaterialissue />
				
			</LayoutProvider>
	
     
	 <LayoutProvider layouts={aplayout} path="/Addmaterialissue" >
				<Addmaterialissue />
				
			</LayoutProvider>
	 
	 <LayoutProvider layouts={aplayout} path="/Adminmaterialpurchase" >
				<Adminmaterialpurchase />
				
			</LayoutProvider>
	 
	 <LayoutProvider layouts={aplayout} path="/Addmaterialpurchase" >
				<Addmaterialpurchase />
				
			</LayoutProvider>
	 
        
	  <LayoutProvider layouts={aplayout} path="/Adminitem" >
				<Adminitem />
				
			</LayoutProvider>
			
			
			
			<LayoutProvider layouts={aplayout} path="/Adminplace" >
				<Adminplace />
				
			</LayoutProvider>
			
			<LayoutProvider layouts={aplayout} path="/Adminemployee" >
				<Adminemployee />
				
			</LayoutProvider>
			
			<LayoutProvider layouts={aplayout} path="/Loginmaster" >
				<Loginmaster />
				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={aplayout} path="/Changepassword" >
				<Changepassword />
				
			</LayoutProvider>
			
			 <Route path="/Logout" component={Logout}/>
			 <Route path="/AppLayout" component={AppLayout}/>
			  <Route path="/Displaymenu" component={Displaymenu}/>
			 <Route path="/Login" component={Login}/>
			
				<Route exact path="/" title="Top Note" component={Login}
			
			 
			 render={() => {
        return (            
             <Redirect to="/Login" />        
          )
          }}></Route>
			
			
			
				
			
			
     </Switch>  </HashRouter>
	);
	
}
	
	
	
	
}


export default App;
