import React ,{Component} from 'react';
import './styles.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import { Page, Section } from 'react-page-layout';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
 
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default class  changepassword extends Component {


 constructor(props) {
    super();

    this.state = { logredirect:false,pagename:'',OldPassword: '' ,NewPassword:'',ConfirmNewPassword:'',ln_id:'',
	
	btndisabled:false,
submitbtn:"Change Password",showPassword:false,NewshowPassword:false,
OldshowPassword:false,
	};
   
}




myChangeHandler = (event) => {
	
	  const re =/^\-{0,1}\d+(.\d+){0,1}$/;
	  
	  
  let nam = event.target.name;
  let val = event.target.value;
    
  this.setState({[nam]: val});
  
  
}




componentDidMount() {
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
}
validateForm() {
    return (
      this.state.OldPassword.length > 0 &&
      this.state.NewPassword.length > 0 &&
      this.state.NewPassword === this.state.ConfirmNewPassword
    );
}


handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
  
handleChange = event => {
let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 };

 handleChangeClick =  (event) => {
    event.preventDefault();
   let Ruserid = localStorage.getItem("TNuserid");
   let OldPassword = this.state.OldPassword;
 
   let NewPassword = this.state.NewPassword;
   let ConfirmNewPassword = this.state.NewPassword;
   
   
   if(OldPassword==="")
{
	this.setState({errormessage:"Please Enter Old Password.",open:true});
	return false;
}
   if(NewPassword==="")
{
	this.setState({errormessage:"Please Enter New Password.",open:true});
	return false;
}
   
    if(NewPassword!=ConfirmNewPassword)
{
	this.setState({errormessage:"Please ReType Correct Password.",open:true});
	return false;
}
  
  if(NewPassword!=ConfirmNewPassword)
  {
	 this.setState({errormessage:"Please ReType Correct Password.",open:true}); 
 
  return false;
  }
    this.setState({btndisabled:true,submitbtn:"Processing..."}) 
var formData = new FormData();
    formData.append('action','changepaswrd')
	formData.append('ln_id',localStorage.getItem("TNuserid"))
	formData.append('oldpassword',OldPassword)
	formData.append('newpassword',NewPassword)
	
	
 axios.post(Serverurl+"ajaxlogin.php", formData, {
        }).then(res => {
			let strreturn=res.data 	;
			if(strreturn === 0)
 {
	 alert("Your Current Password is not Valid!")
	 this.setState({
			   btndisabled:false,
	 submitbtn:"Change Password",})
 }else
 {
	alert("Password has been updated Successfully!") 
	 this.setState({
			   btndisabled:false,
	 submitbtn:"Change Password",
	 OldPassword:'',
NewPassword:'',
ConfirmNewPassword:''	 
	 })
	 
	if(localStorage.getItem("TNlogin")==="0")
		  {
			  
let myItem = localStorage.getItem('TNusername');
	let myItem1 = localStorage.getItem('TNpassword');
localStorage.clear();
localStorage.setItem('TNusername',myItem);
  localStorage.setItem('TNpassword',myItem1);
		   this.setState({
			   
			   
            logredirect: true,
			pagename:'Login'
          })
		  }
 }
    
	});
    
    
  };

 handleClickShowPassword = () => {
    
	this.setState({
            showPassword: !this.state.showPassword
          })
  };
  
  handleClickOldShowPassword = () => {
    
	this.setState({
            OldshowPassword: !this.state.OldshowPassword
          })
  };
  
  
   handleClickNewShowPassword = () => {
    
	this.setState({
            NewshowPassword: !this.state.NewshowPassword
          })
  };
	
	  handleMouseDownPassword = event => {
    event.preventDefault();
  };

	  handleMouseDownPassword1 = event => {
    event.preventDefault();
  };	


  handleMouseDownPassword2 = event => {
    event.preventDefault();
  };
  
  
render() {

if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
return (


  <Page layout="public">



<Section slot="header"> Change Password
</Section>

<Section slot="main"> 				
					
<React.Fragment>
<div className="ordtbl">
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>



<form className={useStyles.form} onSubmit={this.handleChangeClick}>

<div className="changepassword">
 

<div className="changepasswordbg">
<p>Old Password <span className="validationtxt">*</span></p>
 
		  
<p className="chngtxt"><FormControl fullWidth  variant="outlined">
 
          <OutlinedInput value={this.state.OldPassword}
            id="OldPassword" name="OldPassword" 
			inputProps={{maxLength:15,  }} 
			type={this.state.OldshowPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickOldShowPassword}
                  onMouseDown={this.handleMouseDownPassword}  >
				  { this.state.OldshowPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
         
        </FormControl>	</p>  
		  
		  
		  


<p >New Password <span className="validationtxt">*</span></p>
         
		  
		  
		  		  
<p className="chngtxt"><FormControl fullWidth  variant="outlined">
 
          <OutlinedInput value={this.state.NewPassword}
            id="NewPassword" name="NewPassword" 
			inputProps={{maxLength:15,  }} 
			type={this.state.NewshowPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickNewShowPassword}
                  onMouseDown={this.handleMouseDownPassword1}  >
				  { this.state.NewshowPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
         
        </FormControl>	</p>  


<p>Confirm Password <span className="validationtxt">*</span></p>

		  
		  		  
<p className="chngtxt"><FormControl fullWidth  variant="outlined">
 
          <OutlinedInput value={this.state.ConfirmNewPassword}
            id="ConfirmNewPassword" name="ConfirmNewPassword" 
			inputProps={{maxLength:15,  }} 
			type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword2}  >
				  { this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
         
        </FormControl>	</p>  

 <p className="searchbutton"><Button
            type="submit"
           halfWidth
			disabled={this.state.btndisabled}
            variant="contained" 
            color="primary"
           id="searchform" 
            className={useStyles.submit}
          >
            {this.state.submitbtn}
          </Button></p>

</div></div>
</form>

</div >

</React.Fragment>
 </Section></Page>
     );
    }
   }

