import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from './images/topnote-logo.jpg';

function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {'© Copyright '}
	   {new Date().getFullYear()}
      <Link color="inherit" >
       {' '}  Topnote Aromatics, Mysore. All rights reserved.
      </Link>{' '}<br/>
	  
      
     
    </Typography>
  );
}

class Master extends Component {
	
	constructor(props) {
    super(props);
	
	 this.state = {open:false,redirect:false,pagename:''}
	}
	componentDidMount() {
 
  
  
  }
  
   
 
	
	
	render() {
		
	
		return (
			<div>
			
				
				<React.Fragment> 
<div className="headermain">
<div className="main">
<div className="headingsec">
 <img src={logo} />
</div>
</div>
</div>


				
<div className="main">	<div className="heading">
<h1><Slot name="header"  /></h1></div>


<div className="backbutton">
<Slot name="backbutton"  /></div><div className="clear"></div>

<div className="contentmain">
<Slot name="main" /></div>
</div>				
				
	
				
<footer className="footer">
<div className="footersection">
  <Copyright />
     
</div>
</footer>
				
</React.Fragment> 	
			
		</div>
		);
	}
}

export default Master;