import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
import viewimg from './images/view.png';

const headCells = [


  { id: '',  label: '' },
  
  { id: 'tr_date', numeric: false, label: 'Date' },
  { id: 'tr_invoiceno', numeric: false, label: 'Slip No.' },

  { id: 'prdn', numeric: false, label: 'Production Unit' },
  { id: 'godown', numeric: false, label: 'Godown' },
   
    { id: 'tr_customer', numeric: false, label: 'Customer' },
  
 
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Adminproductionsale extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      matpurdata:[],itid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderby:'tr_id',reference:'',godown:'',godownarray:[],
	  productionarray:[],prdn:'',emparray:[],emp:'',invoice:'',
	  searcharray:[],product:'',producttags:[],
	  productid:'',customer:''
	          
      }
       
	   
	}
	
	
	componentDidUpdate() {
		localStorage.removeItem("printtype");
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	
	componentDidMount() {  
	localStorage.removeItem("printtype");
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	
	document.title = "Top Note - Production Sale";
	this.Godownbind();
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 



Godownbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  godownarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.godownarray.map(godown => {
                            
          return {value: godown.pl_id, display:godown.pl_title}
        });
      
		
        this.setState({
          godownarray: [{value: '', display: 'All Godown'}].concat(roleFromApi)
        });
      })
	
this.Productionbind();
}
	

Productionbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=2")
    
      .then((response) => {    
     
      this.setState({  productionarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.productionarray.map(prdn => {
                            
          return {value: prdn.pl_id, display:prdn.pl_title}
        });
      
		
        this.setState({
          productionarray: [{value: '', display: 'All Production'}].concat(roleFromApi)
        });
      })
	

	this.Employeebind();
}
 


Employeebind(){
	
	
	 axios.get(Serverurl+"ajaxempolyee.php?action=list")
    
      .then((response) => {    
     
      this.setState({  emparray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.emparray.map(emp => {
                            
          return {value: emp.em_id, display:emp.em_name}
        });
      
		
        this.setState({
          emparray: [{value: '', display: 'All Employee'}].concat(roleFromApi)
        });
      })
	

	
}

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	  axios.get(Serverurl+'ajaxtransaction.php?action=list&tr_type=4&tr_productionid='+this.state.prdn+'&tr_godownid='+this.state.godown+'&tr_invoiceno='+this.state.invoice+'&tr_customer='+this.state.customer+'&product='+this.state.productid+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  matpurdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			
			let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		let copy=[100,200,300,400,500];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({matpurdata:[],totaldata:0});
	 }
  //alert(this.state.prevdis_icon);
    });
}

handleSubmit= (event) => {
    
    event.preventDefault();
	
	let reference = this.state.reference;
	let godown = this.state.godown;
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 
  
  
  


      
   MatpurchaseDelete(trid,trgodownid ){
	
	
	   axios.get(Serverurl+'ajaxtransaction.php?action=transactionitemgetbyid&ti_tr_id='+trid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
		this.setState({pvdata:resp.data});
   
	}
	
});
setTimeout(() => this.Delete(trid,trgodownid),500);
  }   
	   
	   
  


 Delete(trid,trgodownid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic

 
let optionsVal = this.state.pvdata;


		let sep1="";
		let optionValues="";
		let index=0;
		 let type=4;
		
    optionsVal.forEach(eachval => {
     
		  
	   if(eachval.ti_id>0)
	   	   {
	  optionValues=optionValues+sep1+eachval.title+"~"+eachval.year+"~"+eachval.ti_quantity+"~"+eachval.ti_id+"~1~"+eachval.oldti_quantity;
   
sep1="`"; 
	   
	   }
		   
		   
		   
	 
    })
	//alert(optionValues);
			
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('tr_id',trid)
	 formData.append('tr_godownid',trgodownid)
	 formData.append('tr_type',4)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	formData.append('transactiondetails',optionValues)
	
	 axios.post(Serverurl+"ajaxtransaction.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
        }
    	  
	  
onloadProductBind=(event)=>{
	
		axios.get(Serverurl+'ajaxitem.php?action=productautoextendertype&it_type=2&it_title='+event.target.value)
  .then(resp => { 
 
  	
	 this.setState({searcharray:resp.data});
	
  });
	}		
  

onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      producttags: values,
	   product: values["title"],
	   productid: values["year"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }
  	  
      

render() {
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  return (
  <Page layout="public">
<Section slot="header"> Production Sale
</Section>

<Section slot="backbutton"> <a href='#/Addproductionsale'>New</a>
</Section>

 <Section slot="main">
 
  <React.Fragment>
  <div className="serachcont">
  <div className="serachcnt">
  <ul>
  
  
   
  <li> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadProductBind} 
 variant="outlined" id="search" placeholder="Product" />
        )}
      /></li>
  
  
  
  
  
   <li>
 
	
        
			<p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.invoice}
            id="invoice"
          
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
            placeholder="Slip No."
			
          /></p>
  
  </li>
   
   <li>
 
		<p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.customer}
            id="customer"
          
            name="customer" onChange={this.myChangeHandler}
            autoComplete="customer"
            placeholder="Customer"
			
          /></p>
  
  </li>
  
  
  <li>
 
	
        
			<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.godownlabel, value: this.state.godown }}
          onChange={this.myChangeHandler}
          id="godown"
		   value={this.state.godown}
		  name="godown"
		
		
        >
		{this.state.godownarray.map(godown => (
            <option  
            
              value={godown.value}
            >
              {godown.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
  
  </li>
  
  <li>
  
  							

		<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.prdnlabel, value: this.state.prdn }}
          onChange={this.myChangeHandler}
          id="prdn"
		   value={this.state.prdn}
		  name="prdn"
		
		
        >
		{this.state.productionarray.map(prdn => (
            <option  
            
              value={prdn.value}
            >
              {prdn.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
  </li>
</ul>
  </div>
<div className="submitbtns">
<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  

			   
	</div>
<div className="clear"></div>
  </div>		   
  
		  
		
		
     
<div className="ordtbl">

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
</TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.matpurdata.length}
            />
                      <TableBody>
                     {(this.state.matpurdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >
<TableCell align="left">





{ (localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4" ) && (
<a className="link" href={"#/Addproductionsale?trid="+item.tr_id}>
<img src={editimg} /> </a>)}
&nbsp;&nbsp;

{ (localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4"  ) && (
 <img className="pointer" src={deleteimg} onClick={()=>{this.MatpurchaseDelete(item.tr_id,item.tr_godownid)}} /> 

)}
&nbsp;&nbsp;
<a className="pointer" href={"#/Saleproductionviewmore?trid="+item.tr_id+""}>
 <img src={viewimg}  /> 
</a>



 </TableCell>


<TableCell>{item.tr_date}</TableCell>
<TableCell>{item.tr_invoiceno}</TableCell>


<TableCell>{item.prdn}</TableCell>
<TableCell>{item.godown}</TableCell>
<TableCell>{item.tr_customer}</TableCell>


 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="6">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
  </div>  

 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}
