import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import axios from 'axios';
import Serverurl from './Apiurl';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  

import { Redirect  } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SlideToggle from "react-slide-toggle";
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CottageIcon from '@mui/icons-material/Cottage';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Applayouts from './AppLayout';
import BallotIcon from '@mui/icons-material/Ballot';
const createHistory = require("history").createBrowserHistory;
const useStyles = makeStyles((theme) => ({ 
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 

heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },  
  
}));
	

 class Displaymenu extends Component {
	
	constructor(props) {
    super();
	
	 this.state = {menudata:[],paydata:[],logredirect:false,pagename:'',order:'desc',comments:'',psid:0 ,orderBy:'ps_id',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:10,rowsPaging:[],redirect:false,vredirect:false
	,isExpanded:'',expanded:false,setExpanded:'',panel:'',anchorEl:null,menuopen:null,}
	
	 this.updateDonenewParent = this.updateDonenewParent;
	}
		
updateDonenewParent = (text) => {this.componentDidMount();	}


componentDidMount() {
	


	}

	
	render() {
 const {onToggle, setCollapsibleElement, progress } = this.state;
    // const {expanded, setExpanded} = this.state;
  
		return ( 
			<div>
				
<div className="mastermain">
  
 {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
<a href={"#/Admindashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
 
 )}
 
  {localStorage.getItem("TNrole") === "3" && (
<a href={"#/Managerdashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
 
  )}
  
  
  
  {localStorage.getItem("TNrole") === "3" && (
  
  <a href={"#/Adminmaintainance"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance">
 <IconButton aria-label="Maintainance">
 <ImportExportIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance" />
          </ListItem></a>
  
  )}
  
  {localStorage.getItem("TNrole") === "5" && (
<a href={"#/Managerdashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
 
  )}
  
  
 
 {localStorage.getItem("TNrole") === "5" && (
 
  <a href={"#/Maintainancereport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance Report">
 <IconButton aria-label="Maintainance Report">
 <ReceiptLongIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance Report" />
          </ListItem></a>
		  
 )}	  
	
 
 
  {localStorage.getItem("TNrole") === "5" && (
 
  <a href={"#/Returanableitemsreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Returanable Items Report">
 <IconButton aria-label="Returanable Items Report">
 <ReceiptLongIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Returanable Items Report" />
          </ListItem></a>
 
 
 )}

 {localStorage.getItem("TNrole") === "4" && (

<a href={"#/Adminmarkitems"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Adjust Inventory">
 <IconButton aria-label="Adjust Inventory">
 <LibraryAddCheckIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Adjust Inventory" />
          </ListItem></a>
 )}	  
		  
 {localStorage.getItem("TNrole") === "4" && (	  
	<a href={"#/Markedproducts"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Adjust inventory Records">
 <IconButton aria-label="Adjust inventory Records">
 <BallotIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Adjust inventory Records" />
          </ListItem></a>	  
		  
		  

 )}
 
 
   {(localStorage.getItem("TNrole") === "1"  || localStorage.getItem("TNrole") === "4" || localStorage.getItem("TNrole") === "2") && (

<SlideToggle
    duration={800}
	collapsed
    interpolateOnReverse
    render={({ onToggle, setCollapsibleElement, progress }) => (
      <div className="my-collapsible">
	  
	   
	  
          
         <ListItem button className="my-collapsible__toggle" onClick={onToggle}  >
        <ListItemIcon>
		<Tooltip title="Masters">
 <IconButton aria-label="Masters">
 <ViewTimelineIcon />
    </IconButton>
 </Tooltip>
     </ListItemIcon>
		
		<ListItemText primary="Masters" /> 
       
		</ListItem>
        <div className="my-collapsible__content" ref={setCollapsibleElement}>
          <div
            className="my-collapsible__content-inner"
            style={{
              transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
            }}
			  
          >
		
           
					  
 
	 {this.props.submenuopen === true && ( <React.Fragment> 
  
  	
	
 
 <a href={"#/Adminitem"}> <ListItem button >
          <ListItemText primary="Item Management" />
          </ListItem></a>
		  
   <a href={"#/Adminplace"}> <ListItem button >
          <ListItemText primary="Place Management" />
          </ListItem></a>
 
<a href={"#/Adminemployee"}> <ListItem button >
          <ListItemText primary="Employee Management" />
          </ListItem></a>
		  
<a href={"#/Loginmaster"}> <ListItem button >
          <ListItemText primary="User Management" />
          </ListItem></a>
		
		
	 </React.Fragment>	
		)}		
		
          </div>
        </div>
      </div>
    )}
  />

   )}
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (

<a href={"#/Adminmaterialpurchase"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Purchase">
 <IconButton aria-label="Purchase">
 <AssignmentReturnedIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Purchase" />
          </ListItem></a>

   )}
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (

<a href={"#/Adminmaterialissue"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Issue">
 <IconButton aria-label="Issue">
 <DriveFileMoveIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Issue" />
          </ListItem></a>

   )}
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4" ) && (

<a href={"#/Adminproduction"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Production">
 <IconButton aria-label="Production">
 <CottageIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Production" />
          </ListItem></a>

   )}
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
   
<a href={"#/Adminproductionsale"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Sale">
 <IconButton aria-label="Sale">
 <HomeWorkIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Sale" />
          </ListItem></a>

   )}
   
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4") && (

<a href={"#/Adminmaintainance"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance">
 <IconButton aria-label="Maintainance">
 <ImportExportIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance" />
          </ListItem></a>




   )}
   
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4" ) && (
<SlideToggle
    duration={800}
	collapsed
    interpolateOnReverse
    render={({ onToggle, setCollapsibleElement, progress }) => (
      <div className="my-collapsible">
	  
	  
	  
       
         <ListItem button className="my-collapsible__toggle" onClick={onToggle}>
      
 <ListItemIcon>
 <Tooltip title="Reports">
 <IconButton aria-label="Reports">
   <ReceiptLongIcon />
   
	 </IconButton>
 </Tooltip>
	  </ListItemIcon> 
		<ListItemText primary="Reports" /> 
       
		</ListItem>
       
		
        <div className="my-collapsible__content" ref={setCollapsibleElement}>
          <div
            className="my-collapsible__content-inner"
            style={{
              transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
            }}
          >
		
  {this.props.submenuopen === true && ( <React.Fragment> 
  
  
  
<a href={"#/Materialpurchasereport"}> <ListItem button >
          <ListItemText primary="Purchase Report" />
          </ListItem></a>

<a href={"#/Materialissuereport"}> <ListItem button >
          <ListItemText primary="Issue Report" />
          </ListItem></a>	  
	 
<a href={"#/Productionreport"}> <ListItem button >
          <ListItemText primary="Production Report" />
          </ListItem></a>	


<a href={"#/Productionsalereport"}> <ListItem button >
          <ListItemText primary="Sale Report" />
          </ListItem></a>

<a href={"#/Returanableitemsreport"}> <ListItem button >
          <ListItemText primary="Returnable Items Report" />
          </ListItem></a>		  
	 
<a href={"#/Maintainancereport"}> <ListItem button >
          <ListItemText primary="Maintainance Report" />
          </ListItem></a>		 
	

<a href={"#/Rawmaterialinventoryreport"}> <ListItem button >
          <ListItemText primary="Raw Material Inventory Report" />
          </ListItem></a>		  
	 
<a href={"#/Productinventoryreport"}> <ListItem button >
          <ListItemText primary="Product Inventory Report" />
          </ListItem></a>	
<a href={"#/Dailyinventoryreport"}> <ListItem button >
          <ListItemText primary="Daily Inventory Report" />
          </ListItem></a>
		  
<a href={"#/Productionissuereport"}> <ListItem button >
          <ListItemText primary="Production and Issue Report" />
          </ListItem></a>
	 		  
	 
	 </React.Fragment>	
		)}	
		
          </div>
        </div>
      </div>
    )}
  />
	  

   )}




</div>
				
				

</div>
		);
		
		
	}
}

export default Displaymenu;



