import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles ,withStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
import viewimg from './images/view.png';

const headCells = [


  { id: '',  label: '' },
  
  { id: 'ma_date', numeric: false, label: 'Date' },
  { id: 'ma_name', numeric: false, label: 'Name' },
  { id: 'ma_phone', numeric: false, label: 'Phone' },
   { id: 'aprstatus', numeric: false, label: 'Status' },
  
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


 const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#5eb530',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2ffeb',
    },
	'&:nth-of-type(even)': {
      backgroundColor: '#ddffcc',
    },
  },
}))(TableRow);

export default class Adminmaintainance extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      maintaindata:[],itid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderby:'ma_id',search:'',itemname:''
	 
	          
      }
       
	   
	}
	
	
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	
	componentDidMount() {  
	
	
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	
	document.title = "Top Note - Maintainance";

	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 



myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	  axios.get(Serverurl+'ajaxmaintainance.php?action=list&ma_name='+this.state.search+'&itemname='+this.state.itemname+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  maintaindata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({maintaindata:[],totaldata:0});
	 }
  
    });
}

handleSubmit= (event) => {
    
    event.preventDefault();
	
	
	let search = this.state.search;
	let itemname = this.state.itemname;
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 
  
  
  


       MaintainDelete(maid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('ma_id',maid)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	
	 axios.post(Serverurl+"ajaxmaintainance.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
	  
	  
    

render() {

if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }	

  return (
  <Page layout="public">
<Section slot="header"> Maintainance
</Section>

<Section slot="backbutton">

 <a href='#/Addmaintainance'>New</a>
 

</Section>

 <Section slot="main">
 
  <React.Fragment>
   <div className="serachcont">
  <div className="serachcnt">
  <ul>
  
  
   <li>
 
	
        
			<p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.itemname}
            id="itemname"
          
            name="itemname" onChange={this.myChangeHandler}
            autoComplete="itemname"
            placeholder="Item Name"
			
          /></p>
  
  </li>
  
  
   <li>
 
	
        
			<p ><TextField
            variant="outlined"
            margin="normal"
         
            fullWidth
			value={this.state.search}
            id="search"
          
            name="search" onChange={this.myChangeHandler}
            autoComplete="search"
            placeholder="Name / Phone"
			
          /></p>
  
  </li>
     
	</ul>
  </div>
<div className="submitbtns">
<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  

			   
			   
  
			   
	</div>
<div className="clear"></div>
  </div>		  
		
		
     
<div className="ordtbl">

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
</TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.maintaindata.length}
            />
                      <TableBody>
                     {(this.state.maintaindata).map((item,index) => {  
          
 if(item.ma_approvedstatus === "1" )
 {
	 var strstyle="red";
 }
 else if(item.ma_approvedstatus === "2")
 {
	 	 var strstyle="red";
 }
 


		  return (
 <React.Fragment>
 
 

 
 <TableRow key={index} Style={{"background-color":strstyle}} >
<TableCell align="left">





{ (localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4" ) && (
<a className="link" href={"#/Addmaintainance?maid="+item.ma_id}>
<img src={editimg} /> </a>)}
&nbsp;&nbsp;

{ (localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4" ) && (
 <img className="pointer" src={deleteimg} onClick={()=>{this.MaintainDelete(item.ma_id)}} /> 

)}
&nbsp;&nbsp;
<a className="pointer" href={"#/Maintainanceviewmore?maid="+item.ma_id+""}>
 <img src={viewimg}  /> 
</a>





 
 
 </TableCell>


<TableCell>{item.ma_date}</TableCell>
<TableCell>{item.ma_name}</TableCell>
<TableCell>{item.ma_phoneno}</TableCell>

<TableCell>{item.aprstatus}</TableCell>

 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
  </div>  

 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}
