import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';


import ReactExport from "react-export-excel";
import ReactToPrint from 'react-to-print';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const headCells = [


 
  
  { id: 'tr_date', numeric: false, label: 'Date' },
  { id: 'ti_itemname', numeric: false, label: 'Raw Material' },
  { id: 'ti_quantity', numeric: false, label: 'Quantity' },
   { id: 'tr_invoiceno', numeric: false, label: 'Slip No.' },
  { id: 'tr_referenceno', numeric: false, label: 'Receipt No.' },
  { id: 'godown', numeric: false, label: 'Godown' },
  
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Materialpurchasereport extends Component {
	
    constructor(props) {
    super(props);
   
   var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	 
	 
   
      this.state = {redirect:false,
      matpurdata:[],itid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderby:'tr_id',reference:'',godown:'',
	  godownarray:[],searcharray:[],product:'',producttags:[],
	  productid:'',exportdata:[],
	  
	  	
date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,			  
	
	  
	          
      }
       
	   
	}
	
	
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	
	componentDidMount() {  
	
if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	document.title = "Top Note - Material Purchase Report";
	this.Godownbind();
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 



Godownbind(){
	
	
	 axios.get(Serverurl+"ajaxplace.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  godownarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.godownarray.map(godown => {
                            
          return {value: godown.pl_id, display:godown.pl_title}
        });
      
		
        this.setState({
          godownarray: [{value: '', display: 'All'}].concat(roleFromApi)
        });
      })
	

	
}


  
 handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }  
    
handtoChange = (date) => {
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	 
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }



myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	  axios.get(Serverurl+'ajaxreports.php?action=matpurchasereport&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&tr_godownid='+this.state.godown+'&product='+this.state.productid+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  matpurdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}
			*/
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({matpurdata:[],totaldata:0});
	 }
  
    });
	
	
	this.exportapicall(page,rowsPerPage);
}
	
	
exportapicall(){
	
	  axios.get(Serverurl+'ajaxreports.php?action=matpurchasereport&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&tr_godownid='+this.state.godown+'&product='+this.state.productid)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({exportdata:[],totaldata:0});
	 }
  
    });
	
	
	
}
	

handleSubmit= (event) => {
    
    event.preventDefault();
	
	let fdate1 = this.state.fdate1;
	let tdate1 = this.state.tdate1;
	let godown = this.state.godown;
	let product = this.state.product;
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 
  
  
  


      
	  
	onloadProductBind=(event)=>{
	
		axios.get(Serverurl+'ajaxitem.php?action=productautoextendertype&it_type=1&it_title='+event.target.value)
  .then(resp => { 
 
  	
	 this.setState({searcharray:resp.data});
	
  });
	}		
  

onTagsChange = (event, values) => {
	if(values !== null){
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      producttags: values,
	   product: values["title"],
	   productid: values["year"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }
}
  

render() {
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  return (
  <Page layout="public">
<Section slot="header">Raw Material Purchase Report
</Section>



<Section slot="backbutton"> 

<div className="reportoptions">
<ul>

<li><p>
		   
  <ExcelFile element={<button class="expbtn"> Export</button>}>
 <ExcelSheet data={this.state.exportdata} name="Purchase Report">
<ExcelColumn label="Date" value="tr_date"/>
<ExcelColumn label="Raw Material" value="ti_itemname"/>
<ExcelColumn label="Quantity" value="ti_quantity"/>
<ExcelColumn label="Unit" value="unit"/>
<ExcelColumn label="Slip No." value="tr_invoiceno"/>
	
<ExcelColumn label="Godown" value="godown"/>



						
                </ExcelSheet>                
            </ExcelFile></p></li>
	
	
&nbsp;&nbsp;	
	
		 <li>
		 
		<ReactToPrint
            trigger={() => <p id="print-barcode" className="printrepbtn"> Print </p>}
            content={() => this.componentRef}
          /> 
		 
		 
		 </li>	

</ul>
</div>
</Section>

 <Section slot="main">
 
  <React.Fragment>
   <div className="serachcont">
  <div className="serachcnt">
  <ul>
  
  <li>

 <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false} inputVariant="outlined"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p></li>
<li>
	
	  <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
        onChange={this.handtoChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false} inputVariant="outlined"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</p></li>
		
		
  <li> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadProductBind}  variant="outlined" id="search" placeholder="Raw Material" />
        )}
      /></li>
  
  
  
  
  <li>
  
  							

		<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.godownlabel, value: this.state.godown }}
          onChange={this.myChangeHandler}
          id="godown"
		   value={this.state.godown}
		  name="godown"
		
		
        >
		{this.state.godownarray.map(godown => (
            <option  
            
              value={godown.value}
            >
              {godown.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
  </li>
			   
   </ul> </div>
<div class="submitbtns">
<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  

			   
			   

  </div>  </div>
		  
		
	<div ref={el => (this.componentRef = el)} >			
     
<div className="ordtbl">

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
</TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.matpurdata.length}
            />
                      <TableBody>
                     {(this.state.matpurdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.tr_date}</TableCell>
<TableCell>{item.ti_itemname}</TableCell>
<TableCell>{item.ti_quantity+" "+item.unit}</TableCell>
<TableCell>{item.tr_invoiceno}</TableCell>
<TableCell>{item.tr_referenceno}</TableCell>
<TableCell>{item.godown}</TableCell>

 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="6">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
  </div>  
  </div>

 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}
