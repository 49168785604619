import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
const headCells = [


  { id: '',  label: '' },
  
  { id: 'em_name', numeric: false, label: 'Name' },
  { id: 'em_phoneno', numeric: false, label: 'Phone Number' },
  { id: 'em_empcode', numeric: false, label: 'Employee Code' },
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Employee extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      employeedata:[],emid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderBy:'em_id',phoneno:'',empcode:'',search:'',name:'',

	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	componentDidMount() {  
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	document.title = "Top Note - Employee";
	
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 





myChangeHandler = (event) => {
		const re = /^[0-9\b]+$/; 
  let nam = event.target.name;
  let val = event.target.value;
  
 

 
 if(nam==="phoneno" )
  {
	
	  
	  if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  
}

apicall(page,rowsPerPage){
	  axios.get(Serverurl+'ajaxempolyee.php?action=list&em_name='+this.state.name+'&em_phoneno='+this.state.phoneno+'&em_empcode='+this.state.empcode+'&Sorting='+this.state.orderby+''+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  employeedata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
		let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	  else{
		   this.setState({employeedata:[],totaldata:0});
	 }
  
  //alert(this.state.prevdis_icon);
    });
}

handleSubmit= (event) => {
    
    event.preventDefault();
	
	let name = this.state.name;
	let phoneno = this.state.phoneno;
	let empcode = this.state.empcode;
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 


       EmployeeDelete(emid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('em_id',emid)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	
	 axios.post(Serverurl+"ajaxempolyee.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
     Employeeaddbox(val){
        this.setState({ emid: val });  
  
         setTimeout(() => this.refs.AddEmployeebox.handleClickOpen(),500);

      }

render() {

if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }


  return (
  <Page layout="public">
<Section slot="header"> Employee
</Section>


<Section slot="backbutton"> 


  <a onClick={()=>{this.Employeeaddbox(0)}} className="pointer" >New</a>

</Section>

 <Section slot="main">
 
  <React.Fragment>
  <div className="serachcont">
  <div className="serachcnt">
  <ul>
  <li>
  
  <p><TextField
            
            variant="outlined"
            fullWidth
			value={this.state.name}
            id="name"            
            name="name" onChange={this.myChangeHandler}
			placeholder="Name"
			
          />
		  </p></li>
		  
		  <li><p><TextField
            
            variant="outlined"
            fullWidth
			value={this.state.phoneno}
            id="phoneno"            
            name="phoneno" onChange={this.myChangeHandler}
			placeholder="Phone Number"
			
          />
		  </p></li>
		  
		   <li><p><TextField
            
            variant="outlined"
            fullWidth
			value={this.state.empcode}
            id="empcode"            
            name="empcode" onChange={this.myChangeHandler}
			placeholder="Employee Code"
			
          />
		  </p> </li>
		  
		  
		  
		  
		  
		   </ul>
  </div>
  
		<div className="submitbtns">  
		<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>   
		  </div>
<div className="clear"></div>
  </div>
		  
		 
 
		  
		
		 
     
<div className="ordtbl">
<AddEmployeebox ref="AddEmployeebox" id={this.state.emid}  updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>


                <Table stickyHeader aria-label="sticky table">  

					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.employeedata.length}
            />
                      <TableBody>
                     {(this.state.employeedata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >
<TableCell align="left"><div className="actionbtns">

<ul><li>
<div className="editbtn">
<img src={editimg} onClick={()=>{this.Employeeaddbox(item.em_id)}} /> 
 
 </div> </li>
 
 
 
  &nbsp;  &nbsp;
<li>
<div className="delbtn">
 <img src={deleteimg} onClick={()=>{this.EmployeeDelete(item.em_id)}} />


 </div></li>
 </ul>
 </div>
 </TableCell>
<TableCell>{item.em_name}</TableCell>
<TableCell>{item.em_phoneno}</TableCell>
<TableCell>{item.em_empcode}</TableCell>

 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   </div>  

 </React.Fragment> 

 </Section>
</Page>

  );
}
}


export  class  AddEmployeebox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,name:'',phoneno:'',empcode:'',em_id:0,errormessage:'',erropen:false,
   
      
	  }
      
  }
  
  Employeegetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxempolyee.php?action=list&em_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      em_id:resp.data[0].em_id,
	  name: resp.data[0].em_name,	  
      phoneno: resp.data[0].em_phoneno,
      empcode: resp.data[0].em_empcode, 	  
	  
    
    });
	//alert(this.state.prevca_icon);
//alert(this.state.)
  }
  
});
	  
  }else{
	 this.setState({  
	 name:'',
	 
	phoneno:'',
	  empcode:'',
	  
	 em_id:0
	  }); 
 }
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open, name:'',phoneno:'',empcode:'',errormessage:'',erropen:false,
   
	  });
		this.Employeegetdata();


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
  	if(nam==="phoneno" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }

}



handleClose = (e) => {

 
 let name=this.state.name;
 let phoneno=this.state.phoneno;
 let empcode=this.state.empcode;
 
 
 if(name==="")
{
	this.setState({errormessage:"Please Enter Name",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}

 
if(phoneno==="")
{
	
	this.setState({errormessage:"Please Enter Phone Number",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}

if(phoneno.length < 10 )
{
	
	this.setState({errormessage:"Please Enter 10 digit Phone Number",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}

if(empcode==="")
{
	this.setState({errormessage:"Please Enter Employee Code",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}

   var formData = new FormData();
  
  
   if(this.state.em_id===0)
	  {
		  formData.append('em_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('em_id',this.state.em_id)
		
          }
	  
	  
	 
        formData.append('action','insertorupdate') 
		
		
		
		formData.append('em_name',name)
		formData.append('em_phoneno',phoneno)
		formData.append('em_empcode',empcode)
		formData.append('userid',localStorage.getItem("TNuserid"))
		  
		axios.post(Serverurl+"ajaxempolyee.php", formData, {
        }).then(res => {
    if(res.data == -1){
		
		this.setState({errormessage:"Employee with the same empcode already exist",erropen:true,open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
		
		
	}
            this.props.updateDonenew();
    
        });
  
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 

render (){

return(
<React.Fragment>

    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Employee</DialogTitle>
      <DialogContent>
	  
	  
	   	
<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	  
	  	
  	<p> Name <span className="validationtxt">*</span>
	
	<TextField name="name" 
	variant="outlined"
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.name}
			 InputProps={{  maxlength: 150,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
						  
			
 
	<p>Phone Number <span className="validationtxt">*</span> 
	<TextField 
	variant="outlined"
	name="phoneno" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.phoneno}
			  InputProps={{  maxlength: 15,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
						  
	<p>Employee Code <span className="validationtxt">*</span>
	<TextField
	variant="outlined"
	name="empcode" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.empcode}
			  InputProps={{  maxlength: 150,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
	
            
			 
						    </DialogContent>
      <DialogActions>
	  
	  <div className="actionbtns">

<ul><li>
<div className="delbtn">
	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
 </div> </li>
 
 &nbsp;  &nbsp;
<li>
<div className="editbtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div></li>
 </ul>
 </div>
      </DialogActions>
    </Dialog>
</React.Fragment>

) }
 
} 
  