import React, { Component } from "react";



//const Serverurl= ["http://localhost/xampp/apoorva/topnote/"]
//const Serverurl= ["http://localhost/xampp/Projects/topnote/"]
const Serverurl= ["http://app.topnote.in/appfiles/"]

export default Serverurl;






