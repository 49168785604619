import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import editimg from './images/edit.png';
import deleteimg from './images/delete.png';
import AddIcon from '@mui/icons-material/Add';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import viewimg from './images/view.png';
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}







const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Managerdashboard extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      dashboarddata:[],purchase:'',sales:'',issue:'',production:'',
	  maintainitemdata:[],approvedata:[]

	          
      }
      
	   
	}
	
	
	
	componentDidMount() {  
	
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	
	document.title = "Top Note -  Dashboard";
	

	this.Approvalscall();
 
} 

Approvalscall(){
	
	 axios.get(Serverurl+"ajaxmaintainance.php?action=dashboardapprovemaintainance")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  approvedata: resp.data  
			   
			});
			
	  
	 
     }
  
    });
	
	this.maintainanceitem();
}


maintainanceitem(){
	
	 axios.get(Serverurl+"ajaxmaintainance.php?action=dashboardmaintainanceitem")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  maintainitemdata: resp.data  
			   
			});
			
	  
	 
     }
  
    });
	
	
	
}

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}


Receiveproduct(mtid){
	

let r = window.confirm("Do you really want to make the item as Received ?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','itemeceived')
	 formData.append('mt_id',mtid)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	
	 axios.post(Serverurl+"ajaxmaintainance.php", formData, {
        }).then(res => {
    
             
   alert("Item Received Successfully...!");
 
    
  setTimeout(() =>  this.maintainanceitem() ,500); 
    
            });
 }	
	
}




render() {
	
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	

  return (
  <Page layout="public">
<Section slot="header"> Dashboard
</Section>



 <Section slot="main">
 
  <React.Fragment>
  
  {localStorage.getItem("TNrole") == 5 && (<React.Fragment>
<div className="dashboardfollowup">
   <div className="followupdiv">
  <h4><AddAlertIcon   /> Approvals</h4>
  
  <Table stickyHeader aria-label="sticky table">
  <TableHead width="100%">
  <TableCell >Vendor</TableCell>
  <TableCell width="22%">Phone No.</TableCell>
   <TableCell width="21%">Type</TableCell>
  <TableCell width="10%">Item</TableCell>
   
  <TableCell width="28%">Expected Date</TableCell>
  <TableCell>{" "} </TableCell>
  </TableHead>
  </Table>
 <div className="dashleadsdiv">
<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
				  
					   
                      <TableBody>

                     {stableSort(this.state.approvedata, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >


   <TableCell >{item.ma_name}</TableCell>
   <TableCell >{item.ma_phoneno}</TableCell>
   	<TableCell >{item.type}</TableCell>
	<TableCell  >{item.mt_name}</TableCell>
	
	<TableCell >{item.mt_expecteddate}</TableCell>
	
	
	<TableCell> 
	{item.ma_approvedstatus === "0" && (	<React.Fragment>
	
	<a className="pointer" href={"#/Maintainmasterviewmore?maid="+item.ma_id+""}>
 <img src={viewimg}  /> 
</a>
	</React.Fragment>)}

	</TableCell> 
	


	


 </TableRow>


				</React.Fragment>	
				);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="13">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
  
  </Paper></div>
   </div>
 
  </div>
 	</React.Fragment>)}
  <br/>


{localStorage.getItem("TNrole") == "3" && (<React.Fragment>
<div className="reurnableitems">
<div className="dashboardfollowup">
   <div className="followupdiv">
  <h4><AddAlertIcon   /> Returnable Items</h4>
  
  <Table stickyHeader aria-label="sticky table">
  <TableHead>
  <TableCell>Vendor</TableCell>
  <TableCell width="22%">Phone No.</TableCell>
  <TableCell width="19%">Item</TableCell>
  <TableCell width="32%">Expected Date</TableCell>
  <TableCell>{" "} </TableCell>
  </TableHead>
  </Table>
 <div className="dashleadsdiv">
<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
				  
					   
                      <TableBody>

                     {stableSort(this.state.maintainitemdata, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >


   <TableCell>{item.ma_name}</TableCell>
   <TableCell>{item.ma_phoneno}</TableCell>
	<TableCell >{item.mt_name}</TableCell>
	
	<TableCell>{item.mt_expecteddate}</TableCell>
	
	
	<TableCell> 
	
	{localStorage.getItem("TNrole") == "5"  && ( <React.Fragment> 

	{item.mt_isreceived === "0" && (	<React.Fragment>
	<div className="recbtn">
 <Button onClick={()=>{this.Receiveproduct(item.mt_id)}}>
  Receive
 </Button>
 </div>
 
 
 
 
 	</React.Fragment>)}
	</React.Fragment>)}
	</TableCell> 
	


	


 </TableRow>


				</React.Fragment>	
				);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="13">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
  
  </Paper></div>
   </div>
 
  </div>  </div>


</React.Fragment>)}


 </React.Fragment> 

 </Section>
</Page>
 
  );
}

}