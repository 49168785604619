import React ,{Component} from 'react'; 
import { styled, useTheme } from '@mui/material/styles';

import axios from 'axios';
import Serverurl from './Apiurl';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Displaymenu from './Displaymenu';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { Redirect  } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { Slot } from 'react-page-layout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from './images/logo-on-menu-opened.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Link from '@material-ui/core/Link';
import Menucloseimg from  './images/close-icon.png';
import OpenMenuimg from  './images/menu-icon.png';
import './styles.css'; 


import SlideToggle from "react-slide-toggle";
import Button from '@mui/material/Button';
import PersonAdd from '@mui/icons-material/PersonAdd';


import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CottageIcon from '@mui/icons-material/Cottage';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import BallotIcon from '@mui/icons-material/Ballot';

const createHistory = require("history").createBrowserHistory;
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {'© Copyright '}
	   {new Date().getFullYear()}
      <Link color="inherit" >
       {' '} Topnote Aromatics, Mysore. All rights reserved.
      </Link>{' '}<br/>
	  
      
     
    </Typography>
  );
}

export default class MiniDrawer extends Component {	
	

  constructor(props) {
    super(props)
    this.state = {

        open:false,reason:'',anchorEl:null,menuopen:null,submenuopen:false,
		network:1,name:'',redirect:false,pagename:'',mainmenu:0
    }
    
}

componentDidMount() {

if(localStorage.getItem("TNuserid")!=null)
 {
	 //this.usernameapi();
 }

 
}	


usernameapi(){
	 axios.get(Serverurl+'ajaxlogin.php?action=list&ln_id='+localStorage.getItem("TNuserid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			  name: resp.data[0].ln_username,  
			 
			});
	}
  });
  
 
 }
 
 handledownclick = (e) => {
	 this.setState({ open:true,submenuopen:true });
 }
 
handleDrawerOpen(val){
        this.setState({ id: val });  
 // alert(val);
       

if(val===0)
{
	this.setState({ open:!this.state.open,submenuopen:true });
}
else{
	this.setState({ open:true,submenuopen:true });
	
}


      }
	  
	/*  
handleDrawerOpen = (e) => {



  this.setState({ open:!this.state.open,submenuopen:true });

//const {onToggle, setCollapsibleElement, progress } = this.state;



};*/
handleDrawerClose = (e) => {
   
  this.setState({ open:!this.state.open,submenuopen:true });
//const {onToggle, setCollapsibleElement, progress } = this.state;


};

 handleClick = (e) => {
   
  this.setState({ anchorEl:e.currentTarget ,menuopen:true});
};

 handleClose = (e) => {
   
  this.setState({ anchorEl:null ,menuopen:false});
};
  

  
  
 userlogout =(anchorEl,menuopen) => (event) => {
	
	
	if(menuopen==true){
	
	let myItem = localStorage.getItem('TNusername');
	let myItem1 = localStorage.getItem('TNpassword');
localStorage.clear();
localStorage.setItem('TNusername',myItem);
  localStorage.setItem('TNpassword',myItem1);
   //location.href = "/Signin/"
	let history = createHistory();
        history.push("#");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;   
	
	}
  };
	
render() {
const {onToggle, setCollapsibleElement, progress } = this.state;

  return (
   <React.Fragment> 
  hello
  
   <Box sx={{ display: 'flex' }}>
      <CssBaseline />
	   <Drawer variant="permanent" open={this.state.open}>
	 
		
		<DrawerHeader>
				
	 { this.state.open!==true && (	
 <IconButton onClick={()=>{this.handleDrawerOpen(0)}} style={{"margin-left":"10px"}} >
 <img src={OpenMenuimg}     />  
    
    </IconButton>
	
	 )} 
				 
			 { this.state.open===true && (
			 
        <IconButton onClick={this.handleDrawerClose}>
         <img src={Menucloseimg}     />
		 
		 
          </IconButton>
			 )}
			
		 
	</DrawerHeader>
	
	 <Divider />
       
      
	  
	  
<List> 	
 {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
 
	<ListItem>

<a href={"#/Admindashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
 
 
</ListItem>
)}

  {localStorage.getItem("TNrole") === "3" && (
  <ListItem>
<a href={"#/Managerdashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
  </ListItem> 
  )}

  
  

    {localStorage.getItem("TNrole") === "5" && (
	  <ListItem>
<a href={"#/Managerdashboard"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Dashboard">
 <IconButton aria-label="Dashboard">
 <DashboardIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Dashboard" />
 </ListItem></a>
  </ListItem>
  )}

 
 

  {localStorage.getItem("TNrole") === "3"  && (
   <ListItem>
  <a href={"#/Adminmaintainance"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance">
 <IconButton aria-label="Maintainance">
 <ImportExportIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance" />
          </ListItem></a>
  </ListItem>
  )}
 

 {localStorage.getItem("TNrole") === "5" && (
 <ListItem>
  <a href={"#/Maintainancereport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance Report">
 <IconButton aria-label="Maintainance Report">
 <ReceiptLongIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance Report" />
          </ListItem></a>
</ListItem> 		  
 )}	



  {localStorage.getItem("TNrole") === "5" && (
  <ListItem>
  <a href={"#/Returanableitemsreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Returanable Items Report">
 <IconButton aria-label="Returanable Items Report">
 <ReceiptLongIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Returanable Items Report" />
          </ListItem></a>
 
 </ListItem>
 )}
 


 {localStorage.getItem("TNrole") === "4" && (
 <ListItem>
<a href={"#/Adminmarkitems"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Adjust Inventory">
 <IconButton aria-label="Adjust Inventory">
 <LibraryAddCheckIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Adjust Inventory" />
          </ListItem></a>
		  </ListItem>
 )}
 
 
 
	  
 {localStorage.getItem("TNrole") === "4" && (	
<ListItem>	 
	<a href={"#/Markedproducts"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Adjust inventory Records">
 <IconButton aria-label="Adjust inventory Records">
 <BallotIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Adjust inventory Records" />
          </ListItem></a>	  
		  
		  
</ListItem>
 )}
 
 
</List> 
 
  <List> 
   {(localStorage.getItem("TNrole") === "1"  || localStorage.getItem("TNrole") === "4" || localStorage.getItem("TNrole") === "2") && (
 <ListItem button onClick={()=>{this.handleDrawerOpen(1)}}>
<SlideToggle

    duration={800}
	collapsed
    interpolateOnReverse
    render={({ onToggle, setCollapsibleElement, progress }) => (
      <div className="my-collapsible">
	  
	   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	  
          
         <ListItem button className="my-collapsible__toggle" onClick={onToggle}   >
        <ListItemIcon>
		<Tooltip title="Masters">
 <IconButton aria-label="Masters" > 
 <ViewTimelineIcon   />
    </IconButton>
 </Tooltip>
     </ListItemIcon>
		
		<ListItemText primary="Masters" /> 
       
		
		</ListItem>
        <div className="my-collapsible__content" ref={setCollapsibleElement}>
          <div
            className="my-collapsible__content-inner"
            style={{
              transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
            }}
			  
          >
		
           
					  
 
	 {this.state.submenuopen === true && ( <React.Fragment> 
  
  
	
 
 <a href={"#/Adminitem"}> <ListItem button >
          <ListItemText primary="Item Management" />
          </ListItem></a>
		  
   <a href={"#/Adminplace"}> <ListItem button >
          <ListItemText primary="Place Management" />
          </ListItem></a>
 
<a href={"#/Adminemployee"}> <ListItem button >
          <ListItemText primary="Employee Management" />
          </ListItem></a>
		  
<a href={"#/Loginmaster"}> <ListItem button >
          <ListItemText primary="User Management" />
          </ListItem></a>
		
		
	 

 </React.Fragment>	
		)}		
		
          </div>
        </div>
      </div>
    )}
  />
 </ListItem>
   )}
   
   
	</List> 
	
	<List> 
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
<ListItem>
<a href={"#/Adminmaterialpurchase"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Purchase">
 <IconButton aria-label="Purchase">
 <AssignmentReturnedIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Purchase" />
          </ListItem></a>
</ListItem>
   )}
   
   
   
   
      
	
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
<ListItem>
<a href={"#/Adminmaterialissue"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Issue">
 <IconButton aria-label="Issue">
 <DriveFileMoveIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Issue" />
          </ListItem></a>

 </ListItem>  )} 
   
   
        
	
   
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4" ) && (
<ListItem>
<a href={"#/Adminproduction"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Production">
 <IconButton aria-label="Production">
 <CottageIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Production" />
          </ListItem></a>

  </ListItem> )}
  


    
	
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4") && (
   <ListItem>
<a href={"#/Adminproductionsale"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Sale">
 <IconButton aria-label="Sale">
 <HomeWorkIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Sale" />
          </ListItem></a>

 </ListItem>  )}
   
   
    
	
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "4" || localStorage.getItem("TNrole") === "2") && (
<ListItem>
<a href={"#/Adminmaintainance"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Maintainance">
 <IconButton aria-label="Maintainance">
 <ImportExportIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Maintainance" />
          </ListItem></a>



</ListItem>
   )}
  
	</List> 
 <List> 
   {(localStorage.getItem("TNrole") === "1" || localStorage.getItem("TNrole") === "2" || localStorage.getItem("TNrole") === "4" ) && (
  <ListItem  button onClick={()=>{this.handleDrawerOpen(1)}}>

<SlideToggle
    duration={800}
	collapsed
    interpolateOnReverse
    render={({ onToggle, setCollapsibleElement, progress }) => (
      <div className="my-collapsible">
	  
	  
	   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       
         <ListItem button className="my-collapsible__toggle" onClick={onToggle}>
      
 <ListItemIcon>
 <Tooltip title="Reports">
 <IconButton aria-label="Reports">
   <ReceiptLongIcon />
   
	 </IconButton>
 </Tooltip>
	  </ListItemIcon> 
		<ListItemText primary="Reports" /> 
       
		</ListItem>
       
		
        <div className="my-collapsible__content" ref={setCollapsibleElement}>
          <div
            className="my-collapsible__content-inner"
            style={{
              transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
            }}
          >
		
  {this.state.submenuopen === true && ( <React.Fragment> 
  
  
  
<a href={"#/Materialpurchasereport"}> <ListItem button >
          <ListItemText primary="Purchase Report" />
          </ListItem></a>

<a href={"#/Materialissuereport"}> <ListItem button >
          <ListItemText primary="Issue Report" />
          </ListItem></a>	  
	 
<a href={"#/Productionreport"}> <ListItem button >
          <ListItemText primary="Production Report" />
          </ListItem></a>	


<a href={"#/Productionsalereport"}> <ListItem button >
          <ListItemText primary="Sale Report" />
          </ListItem></a>

<a href={"#/Returanableitemsreport"}> <ListItem button >
          <ListItemText primary="Returnable Items Report" />
          </ListItem></a>		  
	 
<a href={"#/Maintainancereport"}> <ListItem button >
          <ListItemText primary="Maintainance Report" />
          </ListItem></a>		 
	

<a href={"#/Rawmaterialinventoryreport"}> <ListItem button >
          <ListItemText primary="Raw Material Inventory Report" />
          </ListItem></a>		  
	 
<a href={"#/Productinventoryreport"}> <ListItem button >
          <ListItemText primary="Product Inventory Report" />
          </ListItem></a>	
<a href={"#/Dailyinventoryreport"}> <ListItem button >
          <ListItemText primary="Daily Inventory Report" />
          </ListItem></a>
	 <a href={"#/Productionissuereport"}> <ListItem button >
          <ListItemText primary="Production and Issue Report" />
          </ListItem></a>
	 </React.Fragment>	
		)}	
		
          </div>
        </div>
      </div>
    )}
  />
	  
 </ListItem > 
   )}
      
       
		
		
  
        
     
	 
        </List>
        <Divider />
       
	
		  
	      </Drawer>
     
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
       <div className="masthead">
	   
	    <div className="mastheadleft">
	   <img src={logo} />
	   </div>
	   
	   
	    <div className="mastheadright">
	   <p className="welcometext">Welcome</p>
	  
	   
	   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
       
          <div onClick={this.handleClick} size="small" sx={{ ml: 2 }}>
        <p className="pointer"><b>{localStorage.getItem("TNusername")}</b> <AccountCircleIcon /></p>
          </div>
       
      </Box>
      <Menu
        anchorEl={this.state.anchorEl}
        open={this.state.menuopen}
        onClose={this.handleClose}
        onClick={this.handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        
       
        <div className="popupwindow">    
        <MenuItem>
         
		  <div className="setingdiv">
		    <a href='#/Changepassword' className="colorlink"> 
			 <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
            Change Password
          </a>
		  </div>
        </MenuItem>
		
		 	
		
        <MenuItem>
          
		 
		    <div className="setingdiv">
		   <ListItem button  onClick={this.userlogout(this.state.anchorEl,this.state.menuopen)}>
		   <ListItemIcon >
            <Logout fontSize="small" />
          </ListItemIcon>
            Logout
           </ListItem>
		   </div>
		 
		 
        </MenuItem>
		 </div>
      </Menu>
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   </div>
	   
	   <div className="clear"></div>
	   
	   </div>
	   
	   
	   
	  <div className="contentmain"> 
       <React.Fragment> 



				
<div className="main">	


<div className="headmain">
<div className="heading">
<h1><Slot name="header"  /></h1></div>


<div className="backbutton">
<Slot name="backbutton"  /></div><div className="clear"></div>
</div>



<div className="contentmain">
<Slot name="main" /></div>
</div>				

		<br/>		
<footer className="footer">
<div className="footersection">
  <Copyright />
     
</div>
</footer>
</React.Fragment>				
	</div>				
 	



      </Box>
    </Box>
  
  </React.Fragment>  
  );
}
}
