import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import ReactExport from "react-export-excel";
import ReactToPrint from 'react-to-print';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const headCells = [


 
  
  { id: 'ma_date', numeric: false, label: 'Date' },
  { id: 'ma_name', numeric: false, label: 'Name' },
  { id: 'ma_phoneno', numeric: false, label: 'Phone' },
   { id: 'ma_invoice', numeric: false, label: 'Invoice No.' },
  
  { id: 'mt_name', numeric: false, label: 'Item Name' },
  { id: 'mt_amount', numeric: false, label: 'Amount' },
   { id: 'mt_expecteddate', numeric: false, label: 'Expected Date' },
  { id: 'type', numeric: false, label: 'Item Type' },
  
  { id: 'received', numeric: false, label: 'Item Status' },
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Returanableitemsreport extends Component {
	
    constructor(props) {
    super(props);
   
   var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	 
	 
   
      this.state = {redirect:false,
      matpurdata:[],itid:'',page: 0 ,prevpage:0,totaldata:0,
	  rowsPerPage:100,rowsPaging:[],order:'desc' ,orderby:'ma_id',
	  maname:'',mtname:'',type:'',invoice:[],receive:'',exportdata:[],
	  
	  	
date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,			  
	
	  
	          
      }
       
	   
	}
	
	
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	
	componentDidMount() {  
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	document.title = "Top Note - Returnable Items Report";

	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 


  
 handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }  
    
handtoChange = (date) => {
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	 
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }



myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	  axios.get(Serverurl+'ajaxreports.php?action=returnmaintainancereport&ma_name='+this.state.maname+'&ma_invoice='+this.state.invoice+'&mt_isreceived=0&mt_type=1&mt_name='+this.state.mtname+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  matpurdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({matpurdata:[],totaldata:0});
	 }
  
    });
	this.exportapicall();
}



exportapicall(){
	
	  axios.get(Serverurl+'ajaxreports.php?action=returnmaintainancereport&ma_name='+this.state.maname+'&ma_invoice='+this.state.invoice+'&mt_isreceived=0&mt_type=1&mt_name='+this.state.mtname)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	 else{
		   this.setState({exportdata:[],totaldata:0});
	 }
 
    });
}


handleSubmit= (event) => {
    
    event.preventDefault();
	
	
	let maname = this.state.maname;
	let mtname = this.state.mtname;
	let type = this.state.type;
	let invoice = this.state.invoice;
	let receive = this.state.receive;
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 
  
  
  


      
	 

render() {

if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }


  return (
  <Page layout="public">
<Section slot="header">Returnable Items Report
</Section>

<Section slot="backbutton"> 

<div className="reportoptions">
<ul>

<li><p>
		   
  <ExcelFile element={<button class="expbtn"> Export</button>}>
 <ExcelSheet data={this.state.exportdata} name="Returnableitems Report">
<ExcelColumn label="Date" value="ma_date"/>
<ExcelColumn label="Name" value="ma_name"/>
<ExcelColumn label="Phone" value="ma_phoneno"/>
<ExcelColumn label="Invoice No." value="ma_invoice"/>
	
<ExcelColumn label="Item Name" value="mt_name"/>
<ExcelColumn label="Amount" value="mt_amount"/>
<ExcelColumn label="Expected Date" value="mt_expecteddate"/>
<ExcelColumn label="Item Type" value="type"/>	
<ExcelColumn label="Item Status" value="received"/>	
						
                </ExcelSheet>                
            </ExcelFile></p></li>
	
	
&nbsp;&nbsp;	
	
		 <li>
		 
		<ReactToPrint
            trigger={() => <p id="print-barcode" className="printrepbtn"> Print </p>}
            content={() => this.componentRef}
          /> 
		 
		 
		 </li>	

</ul>
</div>
</Section>

 <Section slot="main">
 
  <React.Fragment>
  <div className="serachcont">
  <div className="serachcnt">
  <ul>
  
		
		
  <li>
  <p ><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.maname}
            id="maname"
          
            name="maname" onChange={this.myChangeHandler}
            autoComplete="maname"
            placeholder="Name / Phone "
			
          /></p>
  </li>
  
  
   <li>
  <p ><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "3",maxLength:150 }} 
            fullWidth
			value={this.state.invoice}
            id="invoice"
          
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
            placeholder="Invoice No."
			
          /></p>
  </li>
  
  
   <li>
  <p ><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "4",maxLength:150 }} 
            fullWidth
			value={this.state.mtname}
            id="mtname"
          
            name="mtname" onChange={this.myChangeHandler}
            autoComplete="mtname"
            placeholder="Item Name"
			
          /></p>
  </li>
  
  </ul>
  </div>

  
	<div className="submitbtns">

<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  
</div>
<div className="clear"></div>
  </div>	
	

    
 
		  
		
		<div ref={el => (this.componentRef = el)} >	
     
<div className="ordtbl">

<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
</TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.matpurdata.length}
            />
                      <TableBody>
                     {(this.state.matpurdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.ma_date}</TableCell>
<TableCell>{item.ma_name}</TableCell>
<TableCell>{item.ma_phoneno}</TableCell>
<TableCell>{item.ma_invoice}</TableCell>

<TableCell>{item.mt_name}</TableCell>
<TableCell>{item.mt_amount}</TableCell>
<TableCell>{item.mt_expecteddate}</TableCell>
<TableCell>{item.type}</TableCell>

	{item.mt_type === "1" && ( 
<TableCell>{item.received}</TableCell>
	)}
	
	
	{item.mt_type !== "1" && ( 
<TableCell>{"-"}</TableCell>
	)}
	
 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="9">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
  </div>  
</div>
 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}
