import React ,{Component} from 'react'; 
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

import { Page, Section } from 'react-page-layout';
import './styles.css'; 
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import editimg from './images/edit.png';
import deleteimg from './images/delete.png';

const headCells = [


  { id: '',  label: '' },
  
  { id: 'ln_empid', numeric: false, label: 'Employee Code' },
  { id: 'ln_username', numeric: false, label: 'Username' },
  
  { id: 'ln_role', numeric: false, label: 'Role' },
  
  
  ]
  
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Loginmaster extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      logindata:[],lnid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderBy:'ln_id',empid:'',username:'',role:'',search:'',
emparray:[]
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}
	componentDidMount() {  
	
	
	
	if(localStorage.getItem("TNuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Top Note - User Management";
	
	this.Employeebind();
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 





myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}




Employeebind(){
	
	
	 axios.get(Serverurl+"ajaxempolyee.php?action=list")
    
      .then((response) => {    
     
      this.setState({  emparray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.emparray.map(empid => {
                            
          return {value: empid.em_id, display:empid.em_empcode}
        });
      
		
        this.setState({
          emparray: [{value: '', display: 'Select Employee'}].concat(roleFromApi)
        });
      })
	

	
}

apicall(page,rowsPerPage){
	  axios.get(Serverurl+'ajaxlogin.php?action=list&ln_empid='+this.state.empid+'&ln_username='+this.state.username+'&ln_role='+this.state.role+'&Sorting='+this.state.orderby+''+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  logindata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
			  
		    });
			
			let copy=[100,200,300,400,500];
			/*let cnt=this.state.totaldata;
			let page=5;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push( val)
			}*/
			
	  
	  this.setState({rowsPaging:copy  });
	 
     }
	 
	  else{
		   this.setState({logindata:[],totaldata:0});
	 }

    });
}

handleSubmit= (event) => {
   
    event.preventDefault();
	
	let empid = this.state.empid;
	let username = this.state.username;
	let role = this.state.role;
	this.apicall(this.state.page,this.state.rowsPerPage);
	
	
} 

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
  }; 


     LoginDelete(lnid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('ln_id',lnid)
	 formData.append('userid',localStorage.getItem("TNuserid"))
	
	 axios.post(Serverurl+"ajaxlogin.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
     Loginaddbox(val){
        this.setState({ lnid: val });  
  
         setTimeout(() => this.refs.AddLoginbox.handleClickOpen(),500);

      }

render() {
	
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }

  return (
  <Page layout="public">
<Section slot="header"> User Management
</Section>



<Section slot="backbutton"> 
  
  <a onClick={()=>{this.Loginaddbox(0)}} className="pointer" >New</a>

</Section>




 <Section slot="main">
 
  <React.Fragment>
  <div className="serachcont">
  <div className="serachcnt">
  <ul>
  <li>
  
 	<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.emplabel, value: this.state.empid }}
          onChange={this.myChangeHandler}
          id="empid"
		   value={this.state.empid}
		  name="empid"
		
		
        >
		{this.state.emparray.map(empid => (
            <option  
            
              value={empid.value}
            >
              {empid.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 <li> <p><TextField
            
            variant="outlined"
            fullWidth
			value={this.state.username}
            id="username"            
            name="username" onChange={this.myChangeHandler}
			placeholder="Username"
			
          />
		  </p></li>
		  
		   <li><p> <FormControl  className="stadrp">
       
        <Select
        native
         value={this.state.role}
          onChange={this.myChangeHandler}
        name='role'
         id='role'
		  variant="outlined"
		 >
		   
          <option value={''}>All</option>
          <option value={1}>Admin</option>
		  <option value={2}>Store Manager</option>
		  <option value={3}>Maintainance Staff</option>
		    <option value={5}>Maintainance Manager</option>
          
		  
          </Select>
      </FormControl>
		  	   </p></li>
			   
			   
		
			   
  </ul>
  </div>
  <div class="submitbtns">
		<p className="searchbutton"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            className="submitbtn"
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	</p>  

  </div>  </div>
  
		  
		
     
<div className="ordtbl">
<AddLoginbox ref="AddLoginbox" id={this.state.lnid}  updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
  
  

  
                <Table stickyHeader aria-label="sticky table">  


					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.logindata.length}
            />
                      <TableBody>
                     {(this.state.logindata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >
<TableCell align="left">

<div className="actionbtns">

<ul><li>
<div className="editbtn">
<img src={editimg} onClick={()=>{this.Loginaddbox(item.ln_id)}} />  


 </div> </li>
 
 
 
  &nbsp;  &nbsp;
<li>
<div className="delbtn">
<img src={deleteimg} onClick={()=>{this.LoginDelete(item.ln_id)}} />

 </div></li>
 </ul>
 </div>

</TableCell>


<TableCell>{item.em_empcode}</TableCell>
<TableCell>{item.ln_username}</TableCell>

<TableCell>{item.role}</TableCell>

 </TableRow>

        </React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
    </div>

 </React.Fragment> 

 </Section>
</Page>
 
  );
}
}


export  class  AddLoginbox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,empid:'',username:'',password:'',role:'',ln_id:0,
       errormessage:'',erropen:false,emparray:[]
	  }
    
  }
  
  

Employeebind(){
	
	
	 axios.get(Serverurl+"ajaxempolyee.php?action=list")
    
      .then((response) => {    
     
      this.setState({  emparray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.emparray.map(empid => {
                            
          return {value: empid.em_id, display:empid.em_empcode}
        });
      
		
        this.setState({
          emparray: [{value: '', display: 'Select Employee'}].concat(roleFromApi)
        });
      })
	

	
}
  
  Logingetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxlogin.php?action=list&ln_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      ln_id:resp.data[0].ln_id,
	  empid: resp.data[0].ln_empid,	  
      username: resp.data[0].ln_username,
      password: resp.data[0].ln_password, 	  
	   role: resp.data[0].ln_role, 	
    
    });
	//alert(this.state.prevca_icon);
//alert(this.state.)
  }
  
});
	  
  }else{
	 this.setState({  
	 empid:'',
	 
	username:'',
	  password:'',
	  role:'',
	 ln_id:0
	  }); 
 }
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open, empid:'',username:'',password:'',role:'',
    errormessage:'',erropen:false,
	  });
	   this.Employeebind(); 
		this.Logingetdata();


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
  	if(nam==="phone" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }

}



handleClose = (e) => {

 
 let empid=this.state.empid;
 let username=this.state.username;
 let password=this.state.password;
 let role=this.state.role;
 
 
 if(empid==="")
{
	
	
	this.setState({errormessage:"Please Enter Employee Code",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}

 
if(username==="")
{
	
	this.setState({errormessage:"Please Enter Username",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}



if(password==="")
{
	this.setState({errormessage:"Please Enter Password",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}

if(role==="")
{
	this.setState({errormessage:"Please Select Role",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
		 
}

   var formData = new FormData();
  
  
   if(this.state.ln_id===0)
	  {
		  formData.append('ln_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('ln_id',this.state.ln_id)
		
          }
	  
	  
	 
        formData.append('action','insertorupdate') 
		
		
		
		formData.append('ln_empid',empid)
		formData.append('ln_username',username)
		formData.append('ln_password',password)
		formData.append('ln_role',role)
		formData.append('userid',localStorage.getItem("TNuserid"))
		  
		axios.post(Serverurl+"ajaxlogin.php", formData, {
        }).then(res => {
    


	
	if(res.data == -1)
	{
		
		this.setState({errormessage:"User with Employee Code Already Exists",erropen:true,open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
		
		 
		
	}
            this.props.updateDonenew();
    
        });
  
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 


render (){

return(
<React.Fragment>

    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> User Management</DialogTitle>
      <DialogContent>
	  
	  	   	
<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
  	<p>Employee Code <span className="validationtxt">*</span> 
	<p className="dropdown">
		<FormControl  className={useStyles.formControl}  >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.emplabel, value: this.state.empid }}
          onChange={this.myChangeHandler}
          id="empid"
		   value={this.state.empid}
		  name="empid"
		
		
        >
		{this.state.emparray.map(empid => (
            <option  
            
              value={empid.value}
            >
              {empid.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></p>
						  
			
 
	<p>Username <span className="validationtxt">*</span>
	<TextField name="username" 
	variant="outlined"
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.username}
			   InputProps={{  maxlength: 150,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
						  
	<p>Password <span className="validationtxt">*</span>
	<TextField name="password" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   type="password"
	   variant="outlined"
	   value={this.state.password}
			  InputProps={{  maxlength: 150,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
	
		
 <p>Role <span className="validationtxt">*</span></p>
	<p> <FormControl  className="stadrp">
       
        <Select
        native
         value={this.state.role}
          onChange={this.myChangeHandler}
        name='role'
         id='role'
		 variant="outlined"
		  InputLabelProps={{
			  
            shrink: true,
          }}
        >
		   
          <option value={0}>All</option>
		
          <option value={1}>Admin</option>
		  <option value={2}>Store Manager</option>
		  <option value={3}>Maintainance Staff</option>
		 <option value={5}>Maintainance Manager</option>
          
		  
          </Select>
      </FormControl>
		  	   </p>
			   
            
			 
						    </DialogContent>
      <DialogActions>
	   <div className="actionbtns">

<ul><li>
<div className="delbtn">
	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
		</div> </li>
 
 &nbsp;  &nbsp;
<li>
<div className="editbtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div></li>
 </ul>
 </div>
      </DialogActions>
    </Dialog>
</React.Fragment>

) }
 
} 
  